import logic from '@/scripts'

const state = {
    properties: {
        Pi: {
            value: "",
            unit: "bar"
        },
        Ti: {
            value: "",
            unit: "degc"
        },
    },
    mdot: {
        value: "",
        unit: "kg/s"
    },
    parts: [
        {
            flowValue: "",
            flowUnit: "Kv"
        },
        {
            flowValue: "",
            flowUnit: "Kv"
        }
    ],
    economics: {
        mw: {
            value: "",
            currency: "EUR"
        },
        price: {
            value: "",
            currency: "EUR"
        },
        runtime: {
            value: "",
            unit: "hour"
        }
    },
    results: {},
    request: {
        running: false,
        type: 'undefined',
        error: null
    }
}

const getters = {
    getLossProperties: (state) => state.properties,
    getLossMassFlow: (state) => state.mdot,
    getLossParts: (state) => state.parts,
    getLossEconomics: (state) => state.economics,
    getLossResults: (state) => state.results,
    isCalculable: (state) => logic.input.loss.calculateable(state.parts,state.properties.Pi,state.properties.Ti,state.mdot),
    hasCalculatedLoss: (state) => Object.keys(state.results).length > 0,
    isLoadingLoss: (state) => state.request.running,
    isCalculatingLoss: (state) => state.request.type === 'calculating',
    getLossRequestError: (state) => state.request.error,
}

const actions = {
    async calculateLoss({ state, commit }) {
        return new Promise((resolve, reject) => {
            commit('openLossRequest', 'calculating')
            if (logic.input.loss.calculateable(state.parts,state.properties.Pi,state.properties.Ti,state.mdot) === true) {
                var data = {}
                data['properties'] = {
                    Pi: [parseFloat(state.properties.Pi.value), state.properties.Pi.unit],
                    Ti: [parseFloat(state.properties.Ti.value), state.properties.Ti.unit]
                }
                data['mdot'] = [parseFloat(state.mdot.value), state.mdot.unit]
                data['parts'] = []
                state.parts.forEach(part => {
                    data['parts'].push({
                        flowValue: parseFloat(part.flowValue) || null,
                        flowUnit: part.flowUnit
                    })
                })
                data['economics'] = {
                    mwprice: [parseFloat(state.economics.mw.value) || null, state.economics.mw.currency],
                    price: [parseFloat(state.economics.price.value) || null, state.economics.price.currency],
                    runtime: [parseFloat(state.economics.runtime.value) || null, state.economics.runtime.unit]
                }
                logic.request.loss.calculate(data)
                .then(response => {
                    commit("setLossResults",response.data)
                    commit('closeLossRequest')
                    resolve(response)
                })
                .catch(errors => {
                    commit('closeLossRequest')
                    commit('setLossErrorMessage',errors.response.data.message)
                    reject(errors)
                })
                
            } else {
                reject({message: "Incomplete dataset"})
            }
            
        })
    },
    storeLossPropertiesPi({ commit }, value) {
        commit("setLossPropertiesPi", value);
    },
    storeLossPropertiesPiUnit({ commit }, value) {
        let newValue = logic.input.pressure.convert(
          state.properties.Pi.value,
          state.properties.Pi.unit,
          value
        );
        commit("setLossPropertiesPi", newValue);
        commit("setLossPropertiesPiUnit", value);
    },
    storeLossPropertiesTi({ commit }, value) {
        commit("setLossPropertiesTi", value);
    },
    storeLossPropertiesTiUnit({ commit }, value) {
        let newValue = logic.input.temperature.convert(
          state.properties.Ti.value,
          state.properties.Ti.unit,
          value
        );
        commit("setLossPropertiesTi", newValue);
        commit("setLossPropertiesTiUnit", value);
    },
    storeLossMassFlow({ commit }, value) {
        commit("setLossMassFlow", value);
    },
    storeLossMassFlowUnit({ commit }, value) {
        let newValue = logic.input.mdot.convert(
          state.mdot.value,
          state.mdot.unit,
          value
        );
        commit("setLossMassFlow", newValue);
        commit("setLossMassFlowUnit", value);
    },
    storeLossFlowValue({ commit }, {
        index,
        flowValue
    }) {
        commit("setLossPartsFlowValue", {index,flowValue});
    },
    storeLossFlowUnit({ commit }, {
        index,
        flowUnit
    }) {
        let flowValue = logic.input.loss.parts.flowCoefficient.convert(
            state.parts[index].flowValue,
            state.parts[index].flowUnit,
            flowUnit
        );
        commit("setLossPartsFlowValue", {index,flowValue});
        commit("setLossPartsFlowUnit", {index,flowUnit});
    },
}

const mutations = {
    openLossRequest: (state, type) => state.request = { running: true, type: type, error: null},
    closeLossRequest: (state) => state.request = { running: false, type: 'undefined', error: null},
    setLossErrorMessage: (state, message) => state.request.error = message,
    setLossPropertiesPi: (state, Pi) => state.properties.Pi.value = Pi,
    setLossPropertiesPiUnit: (state, unit) => state.properties.Pi.unit = unit,
    setLossPropertiesTi: (state, Ti) => state.properties.Ti.value = Ti,
    setLossPropertiesTiUnit: (state, unit) => state.properties.Ti.unit = unit,
    setLossMassFlow: (state, mdot) => state.mdot.value = mdot,
    setLossMassFlowUnit: (state, unit) => state.mdot.unit = unit,
    setLossPartsFlowValue: (state, {index, flowValue}) => state.parts[index].flowValue = flowValue,
    setLossPartsFlowUnit: (state, {index, flowUnit}) => state.parts[index].flowUnit = flowUnit,
    setLossMWCurrency: (state, cur) => state.economics.mw.currency = cur,
    setLossMWPrice: (state, price) =>  state.economics.mw.value = price,
    setLossCurrency: (state, cur) => state.economics.price.currency = cur,
    setLossPrice: (state, price) => state.economics.price.value = price,
    setLossRunTime: (state, runtime) => state.economics.runtime.value = runtime,
    setLossResults: (state, results) => state.results = results,
}

export default {
    state,
    getters,
    actions,
    mutations
}