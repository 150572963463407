const password = {
    min: 8,
    max: 64,
    minChar: 1,
    minCap: 1,
    minNum: 1,
    minSpec: 1,
    minLength(input) {
        input = String(input)
        return input.length >= password.min
    },
    maxLength(input) {
      input = String(input)
      return input.length <= password.max
    },
    validate(input) {
        return password.minLength(input) && password.maxLength(input)
    }
}

export default password