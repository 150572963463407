import session from '../session'

const projects = {
    getProjects() {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return session.get('/sonic/user/projects/')
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return session.get(`/sonic/token/projects/?device-token=${clientToken}`)
        }
    },
    getProject(project_id) {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return session.get(`/sonic/user/project/${project_id}/`)
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return session.get(`/sonic/token/project/${project_id}/?device-token=${clientToken}`)
        }
    },
    createProject(data) {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return session.post('/sonic/user/project/', data)
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return session.post(`/sonic/token/project/?device-token=${clientToken}`, data)
        }
    }
}

export default projects