import Vue from "vue";
import projectSonic from "./projectSonic.js";
import Router from "vue-router";
import Home from "../views/home.vue";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/sonic",
      name: "sonic",
      component: () =>
        import(/* webpackChunkName: "sonic" */ "../views/sonic/index.vue"),
      meta: {
        requiresAuth: false,
        requiresClient: true,
      },
      beforeEnter: (to, from, next) => {
        projectSonic
          .initialize()
          .then(() => {
            if (to.query.share) {
              projectSonic
                .shareLink(to.query.share)
                .then(() => {
                  // Go to project of share link
                  next();
                })
                .catch(() => {
                  return next({ name: "sonic.view" });
                });
            } else {
              return next();
            }
          })
          .catch(() => {
            return next({ name: "home" });
          });
      },
      children: [
        {
          name: "sonic.view",
          path: "overview",
          component: () =>
            import(
              /* webpackChunkName: "sonicView" */ "../views/sonic/projects/index.vue"
            ),
        },
        {
          name: "sonic.input",
          path: ":id/input",
          component: () =>
            import(
              /* webpackChunkName: "sonicInput" */ "../views/sonic/input/index.vue"
            ),
          beforeEnter: (to, from, next) => {
            if (to.params.id) {
              projectSonic
                .getProject(to.params.id)
                .then(() => {
                  return next();
                })
                .catch((error) => {
                   console.log(error)
                  return next({ name: "sonic.view" });
                });
            } else {
              return next({ name: "sonic.view" });
            }
          },
        },
        {
          name: "sonic.output",
          path: ":id/output",
          component: () =>
            import(
              /* webpackChunkName: "sonicOutput" */ "../views/sonic/output/index.vue"
            ),
          beforeEnter: (to, from, next) => {
            if (to.params.id) {
              projectSonic
                .getProject(to.params.id)
                .then(() => {
                  projectSonic
                    .getResults(to.params.id)
                    .then(() => {
                      return next();
                    })
                    .catch(() => {
                      return next({
                        name: "sonic.input",
                        params: { id: to.params.id },
                      });
                    });
                })
                .catch(() => {
                  return next({ name: "sonic.view" });
                });
            } else {
              return next({ name: "sonic.view" });
            }
          },
        },
      ],
    },
    {
      path: "/loss",
      name: "loss.view",
      component: () =>
        import(/* webpackChunkName: "loss" */ "../views/loss/index.vue"),
      meta: {
        requiresAuth: false,
        requiresClient: true,
      },
    },
    {
      path: "/flow",
      name: "flow.view",
      component: () =>
        import(/* webpackChunkName: "flow" */ "../views/flow/index.vue"),
      children: [
        {
          path: "load",
          name: "flow.load",
          component: () =>
            import(/* webpackChunkName: "flow.load" */ "../views/flow/inputs/water/load/index.vue"),
        },
        {
          path: "design",
          name: "flow.design",
          component: () =>
            import(/* webpackChunkName: "flow.load" */ "../views/flow/inputs/water/design/index.vue"),
        },
        {
          path: "offDesign",
          name: "flow.offDesign",
          component: () =>
            import(/* webpackChunkName: "flow.load" */ "../views/flow/inputs/water/offDesign/index.vue"),
        },
      ],
      meta: {
        requiresAuth: false,
        requiresClient: true,
        development: false,
      },
    },
    {
      path: "/props",
      name: "props",
      component: () =>
        import(/* webpackChunkName: "props" */ "../views/props/index.vue"),
      meta: {
        requiresAuth: false,
        requiresClient: true,
        development: false,
      },
    },
    {
      path: "/feedback",
      name: "feedback",
      component: () =>
        import(
          /* webpackChunkName: "feedback" */ "../views/feedback/index.vue"
        ),
      meta: {
        requiresAuth: false,
        requiresClient: false,
      },
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/user/login/index.vue"),
      meta: {
        requiresAuth: false,
        requiresClient: false,
      },
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(/* webpackChunkName: "register" */ "../views/user/register/index.vue"),
      meta: {
        requiresAuth: false,
        requiresClient: false,
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: () =>
        import(/* webpackChunkName: "logout" */ "../views/user/logout/index.vue"),
      meta: {
        requiresAuth: false,
        requiresClient: false,
      },
    },
    {
      path: "/user",
      name: "user",
      component: () =>
        import(/* webpackChunkName: "user" */ "../views/user/user/index.vue"),
      children: [
        {
          path: "profile",
          name: "user.profile",
          component: () =>
            import(/* webpackChunkName: "user.profile" */ "../views/user/user/profile.vue"),
        }
      ]
    },
    {
      path: "/activate",
      name: "activate.index",
      component: () => import(/* webpackChunkName: "activate.index" */ "../views/user/activate/index.vue"),
      children: [
        {
          name: "activate.register",
          path: "register",
          component: () =>
            import(
              /* webpackChunkName: "activate.register" */ "../views/user/activate/register.vue"
            ),
        },
        {
          name: "activate.activated",
          path: "activate",
          component: () =>
            import(
              /* webpackChunkName: "activate.activate" */ "../views/user/activate/activated.vue"
          ),
        },
        {
          name: "activate.resend",
          path: "resend",
          component: () =>
            import(
              /* webpackChunkName: "activate.activate" */ "../views/user/activate/resend.vue"
          ),
        },
        {
          name: "activate.activate",
          path: ":uid/:token",
          // component: () =>
          //   import(
          //     /* webpackChunkName: "activate.activate" */ "../views/user/activate/index.vue"
          // ),
          beforeEnter: (to, from, next) => {
            if (to.params.uid && to.params.token) {
              store.dispatch("activateAccount", {uid: to.params.uid, token: to.params.token})
              .then(() => {
                next()
              })
              .catch(() => {
                next()
              })
            } else {
              return next({ name: "home" });
            }
          },
        },
      ],
      meta: {
        requiresAuth: false,
        requiresClient: false,
      },
      
    },
    {
      path: "/password",
      name: "password.index",
      component: () => import(/* webpackChunkName: "password.index" */ "../views/user/password/index.vue"),
      children: [
        {
          name: "password.forgotten",
          path: "forgotten",
          component: () =>
            import(
              /* webpackChunkName: "password.forgotten" */ "../views/user/password/forgotten.vue"
            ),
        },
        {
          name: "password.reset",
          path: "reset/:uid/:token",
          component: () =>
            import(
              /* webpackChunkName: "password.reset" */ "../views/user/password/reset.vue"
          ),
        },
        
      ],
      meta: {
        requiresAuth: false,
        requiresClient: false,
      },
      
    },
    
  ],
});

router.beforeEach((to, from, next) => {
  // if (process.env.VUE_APP_HTTPS_REQUIRED === "true") {
  //   if (location.protocol !== "https:") {
  //     location.replace(
  //       `https:${location.href.substring(location.protocol.length)}`
  //     );
  //   }
  // }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch("authUser").then(() => {
      if (store.getters.isUserAuthenticated) {
        return next();
      } else {
        return next({ name: "login" });
      }
    });
  } else if (to.matched.some((record) => record.meta.requiresClient)) {
    store.dispatch("authUser")
    .then(() => {
      return next();
    })
    .catch(() => {
      store.dispatch("authClient")
      .then(() => {
        return next();
      })
      .catch(() => {
        store.dispatch("clientRegister")
        .then(() => {
          return next();
        })
        .catch(() => {
          return next({ name: "home" });
        })
      })
    })
  } else if (to.matched.some((record) => record.meta.development)) {
    if (process.env.development === "true") {
      return next();
    } else {
      return next({ name: "home" });
    }
  } else {
    return next();
  }
});

export default router;
