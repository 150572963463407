import din from './din'
import ansi from './ansi'
import partDiameter from './partDiameter'

const sizing = {
  din: din,
  ansi: ansi,
  partDiameter: partDiameter,
  validate(sizing) {
    if(sizing.standard === "DIN") {
      return din.validate(sizing)
    } else if (sizing.standard === "ANSI") {
      return ansi.validate(sizing)
    } else if (sizing.standard === "COSTUM") {
      return false
    }
    return false
  },
}

export default sizing