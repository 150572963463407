const username = {
    min: 3,
    max: 64,
    minLength(name) {
      name = String(name)
      return name.length >= username.min
    },
    maxLength(name) {
      name = String(name)
      return name.length <= username.max
    },
    validate(input) {
      return username.minLength(input) && username.maxLength(input);
    },
}

export default username