const state = {
    lang: 'english',
    langOptions: [
        { value: "english", text: "English", code: "gb", disabled: false },
        { value: "dutch", text: "Dutch", code: "nl", disabled: true },
        { value: "german", text: "German", code: "de", disabled: true }
    ],
    sonic: {
        input: {
            active: 'general'
        },
        output: {
            active: 'numerical'
        }
    }
}

const getters = {
    getLang: (state) => state.lang,
    getLangOptions: (state) => state.langOptions,
    getActivePageSonicInput: (state) => state.sonic.input,
    getActivePageSonicOutput: (state) => state.sonic.output
}

const actions = {
    setSonicPageActiveTab({commit}, data) {
        if (data.page === 'input') {
            commit('setActivePageSonicInput', data.tab)
        } else if (data.page === 'output') {
            commit('setActivePageSonicOutput', data.tab)
        }
    }
}

const mutations = {
    setLang: (state, lang) => state.lang = lang,
    setActivePageSonicInput: (state, tab) => state.sonic.input.active = tab,
    setActivePageSonicOutput: (state, tab) => state.sonic.output.active = tab,
}

export default {
    state,
    getters,
    actions,
    mutations
}