import logic from '@/scripts'
import ANSI from "./norm/ANSI.js";
import DIN from "./norm/DIN.js";

const state = {
    loadCases: [
        {
            selected: 'flowCoefficient',
            cooling: false,
            sizing: false,
            form: {
                Pi: {
                    value: "",
                    unit: "bar"
                },
                Ti: {
                    value: "",
                    unit: "degc"
                },
                Pe: {
                    value: "",
                    unit: "bar"
                },
                mdot: {
                    value: "",
                    unit: "kg/s"
                },
                flowCoefficient: {
                    value: "",
                    unit: "Kv"
                },
                Tt: {
                    value: "",
                    unit: "degc"
                },
                Tc: {
                    value: "",
                    unit: "degc"
                },
                Pc: {
                    value: "",
                    unit: "bar"
                },
                inletSizing: {
                    standard: "DIN",
                    size: 10,
                    norm: "40",
                    sizeList: DIN,
                    normList: DIN[0].PN
                },
                outletSizing: {
                    standard: "DIN",
                    size: 10,
                    norm: "40",
                    sizeList: DIN,
                    normList: DIN[0].PN
                }
            },
            result: null            
        },
    ],
    design: {
        selected: 0
    },
    offDesign: {
        selected: 0
    },
    settings: {
        form: {
            min: 1,
            max: 6,
            level: "basic",
            units: "metric",
            fluids: "water",
            detail: "advanced",
        },
        stored: {
            min: 1,
            max: 6,
            level: "basic",
            units: "metric",
            fluids: "water",
            detail: "advanced",
        }
    },
    help: {
        active: "load"
    },
    request: {
        index: null,
        running: false,
        type: null,
        message: null
    }
}

const getters = {
    getFlowLoadCases: (state) => state.loadCases,
    getFlowDesignLoadCases: (state) => {
        var loadCases = [];
        state.loadCases.forEach((element, index) => {
            if (element.result !== null) {
                loadCases.push(index)
            }
        })
        return loadCases
    },
    getFlowDesign: (state) => state.design,
    getFlowDesignInput: (state) => state.loadCases[state.design.selected].result,
    getFlowOffDesignDesignCase: () => false,
    getFlowOffDesign: state => state.offDesign,
    getFlowLoadCasesCount: (state) => state.loadCases.length,
    getFlowSettings: (state) => state.settings,
    getFlowHelp: (state) => state.help,
    getFlowRequest: (state) => state.request
}

const actions = {
    addFlowLoadCase({state, commit}) {
        const storedUnits = localStorage.getItem("settingsUnits");
        var presUnit, tempUnit, massUnit, coefUnit;
        if (storedUnits === "imperial") {
          presUnit = "psi"
          tempUnit = "degf"
          massUnit = "lb/s"
          coefUnit = "Cv"
        } else if (storedUnits === "si") {
          presUnit = "mpa"
          tempUnit = "kelvin"
          massUnit = "kg/s"
          coefUnit = "Kv"
        } else {
          presUnit = "bar"
          tempUnit = "degc"
          massUnit = "kg/s"
          coefUnit = "Kv"
        }
        const template = {
            selected: 'flowCoefficient',
            sizing: false,
            cooling: false,
            form: {
                Pi: {
                    value: "",
                    unit: presUnit
                },
                Ti: {
                    value: "",
                    unit: tempUnit,
                },
                Pe: {
                    value: "",
                    unit: presUnit
                },
                mdot: {
                    value: "",
                    unit: massUnit
                },
                flowCoefficient: {
                    value: "",
                    unit: coefUnit
                },
                Tt: {
                    value: "",
                    unit: tempUnit
                },
                Tc: {
                    value: "",
                    unit: tempUnit
                },
                Pc: {
                    value: "",
                    unit: presUnit
                },
                inletSizing: {
                    standard: "DIN",
                    size: 10,
                    norm: "40",
                    sizeList: DIN,
                    normList: DIN[0].PN
                },
                outletSizing: {
                    standard: "DIN",
                    size: 10,
                    norm: "40",
                    sizeList: DIN,
                    normList: DIN[0].PN
                }
            },
            result: null          
        }
        if (state.loadCases.length < state.settings.stored.max) {
            var loadCases = []
            state.loadCases.forEach(element => {
                loadCases.push(element)
            });
            loadCases.push(template)
            commit("setFlowLoadCases", loadCases)
        }
    },
    removeFlowLoadCase({state, commit}, index) {
        if (state.loadCases.length > state.settings.stored.min) {
            var loadCases = []
            state.loadCases.forEach((element, i) => {
                if (index !== i) {
                    loadCases.push(element)
                }
            });
            commit("setFlowLoadCases", loadCases)
        }
    },
    async runFlowCalculate({ state, commit }, { index }) {
        return new Promise((resolve, reject) => {
            commit("openFlowRequest", {type: 'calculate', index: index})
            var data = {}
            if (state.loadCases[index].selected === 'pressure') {
                data = {
                    Pi: state.loadCases[index].form.Pi,
                    Ti: state.loadCases[index].form.Ti,
                    Pe: {
                        value: "",
                        unit: "bar"
                    },
                    mdot: state.loadCases[index].form.mdot,
                    flowCoefficient: state.loadCases[index].form.flowCoefficient,
                    cooling: state.loadCases[index].cooling,
                    Tt: state.loadCases[index].form.Tt,
                    Pc: state.loadCases[index].form.Pc,
                    Tc: state.loadCases[index].form.Tc,
                    sizing: state.loadCases[index].sizing,
                    inletSizing: {
                        standard: state.loadCases[index].form.inletSizing.standard,
                        size: state.loadCases[index].form.inletSizing.size,
                        norm: state.loadCases[index].form.inletSizing.norm,
                    },
                    outletSizing: {
                        standard: state.loadCases[index].form.outletSizing.standard,
                        size: state.loadCases[index].form.outletSizing.size,
                        norm: state.loadCases[index].form.outletSizing.norm,
                    }
                }
            } else if (state.loadCases[index].selected === 'flowCoefficient') {
                data = {
                    Pi: state.loadCases[index].form.Pi,
                    Ti: state.loadCases[index].form.Ti,
                    Pe: state.loadCases[index].form.Pe,
                    mdot: state.loadCases[index].form.mdot,
                    flowCoefficient: {
                        value: "",
                        unit: "Kv"
                    },
                    cooling: state.loadCases[index].cooling,
                    Tt: state.loadCases[index].form.Tt,
                    Pc: state.loadCases[index].form.Pc,
                    Tc: state.loadCases[index].form.Tc,
                    sizing: state.loadCases[index].sizing,
                    inletSizing: {
                        standard: state.loadCases[index].form.inletSizing.standard,
                        size: state.loadCases[index].form.inletSizing.size,
                        norm: state.loadCases[index].form.inletSizing.norm,
                    },
                    outletSizing: {
                        standard: state.loadCases[index].form.outletSizing.standard,
                        size: state.loadCases[index].form.outletSizing.size,
                        norm: state.loadCases[index].form.outletSizing.norm,
                    }
                }
            } else if (state.loadCases[index].selected === 'massFlow') {
                data = {
                    Pi: state.loadCases[index].form.Pi,
                    Ti: state.loadCases[index].form.Ti,
                    Pe: state.loadCases[index].form.Pe,
                    mdot: {
                        value: "",
                        unit: "kg/s"
                    },
                    flowCoefficient: state.loadCases[index].form.flowCoefficient,
                    cooling: state.loadCases[index].cooling,
                    Tt: state.loadCases[index].form.Tt,
                    Pc: state.loadCases[index].form.Pc,
                    Tc: state.loadCases[index].form.Tc,
                    sizing: state.loadCases[index].sizing,
                    inletSizing: {
                        standard: state.loadCases[index].form.inletSizing.standard,
                        size: state.loadCases[index].form.inletSizing.size,
                        norm: state.loadCases[index].form.inletSizing.norm,
                    },
                    outletSizing: {
                        standard: state.loadCases[index].form.outletSizing.standard,
                        size: state.loadCases[index].form.outletSizing.size,
                        norm: state.loadCases[index].form.outletSizing.norm,
                    }
                }
            }
            logic.request.flow.calculate(data)
            .then((response) => {
                // commit("setPropsResult", response.data);
                commit("setFlowResult", { result: response.data, index: index})
                commit("closeFlowRequest");
                resolve(response);
            })
            .catch((errors) => {
                commit("setFlowMessage", errors.response.data.message);
                commit("closeFlowRequest");
                reject(errors);
            });
        })
    },
    getFlowStoreSettings({ state, commit }) {
        const storedLevel = localStorage.getItem("settingsLevel");
        const storedUnits = localStorage.getItem("settingsUnits");
        const storedFluids = localStorage.getItem("settingsFluids");
        const storedDetail = localStorage.getItem("settingsDetail");
        var units, presUnit, tempUnit, massUnit, coefUnit, level, fluids, detail;
        if (storedUnits === "imperial") {
          units = "imperial"
          presUnit = "psi"
          tempUnit = "degf"
          massUnit = "lb/s"
          coefUnit = "Cv"
        } else if (storedUnits === "si") {
          units = "si"
          presUnit = "mpa"
          tempUnit = "kelvin"
          massUnit = "kg/s"
          coefUnit = "Kv"
        } else {
          units = "metric"
          presUnit = "bar"
          tempUnit = "degc"
          massUnit = "kg/s"
          coefUnit = "Kv"
        }
        if (storedLevel === "basic") {
            level = "basic"
        } else if (storedLevel === "expert") {
            level = "expert"
        } else {
            level = "basic"
        }
        if (storedFluids === "water") {
          fluids = "water";
        } else {
          fluids = "water";
        }
        if (storedDetail === "advanced") {
          detail = "advanced";
        } else {
          detail = "basic";
        }
        var index;
        for (index = 0; index < state.loadCases.length; index++) {
            commit("setFlowPiUnit", {value: presUnit, index: index});
            commit("setFlowTiUnit", {value: tempUnit, index: index});
            commit("setFlowPeUnit", {value: presUnit, index: index});
            commit("setFlowMassFlowUnit", {value: massUnit, index: index});
            commit("setFlowFlowCoefficientValue", {value: coefUnit, index: index});
            commit("setFlowTtUnit", {value: tempUnit, index: index});
            commit("setFlowPcUnit", {value: presUnit, index: index});
            commit("setFlowTcUnit", {value: tempUnit, index: index});
        }
        commit("setFlowSettingsLevel", level);
        commit("setFlowSettingsUnits", units);
        commit("setFlowSettingsFluids", fluids);
        commit("setFlowSettingsDetail", detail);
        commit("setFlowSettingsStoredLevel", level);
        commit("setFlowSettingsStoredUnits", units);
        commit("setFlowSettingsStoredFluids", fluids);
        commit("setFlowSettingsStoredDetail", detail);
    },
    storeFlowStoreSettings({ state, commit, dispatch }) {
        if (state.settings.form.units !== localStorage.getItem("settingsUnits")) {
          localStorage.setItem("settingsUnits", state.settings.form.units);
          dispatch("setFlowGlobalUnits", state.settings.form.units)
        }
        if (state.settings.form.level !== localStorage.getItem("settingsLevel")) {
            localStorage.setItem("settingsLevel", state.settings.form.level);
            commit("setFlowSettingsStoredLevel", state.settings.form.level);
        }
        if (state.settings.form.fluids !== localStorage.getItem("settingsFluids")) {
          localStorage.setItem("settingsFluids", state.settings.form.fluids);
          commit("setFlowSettingsStoredFluids", state.settings.form.fluids);
        }
        if (state.settings.form.detail !== localStorage.getItem("settingsDetail")) {
          localStorage.setItem("settingsDetail", state.settings.form.detail);
          commit("setFlowSettingsStoredDetail", state.settings.form.detail);
        }
    },
    setFlowGlobalUnits({commit, dispatch}, units) {
        commit("setFlowSettingsStoredUnits", units);
        localStorage.setItem("settingsUnits", state.settings.form.units);
        var index, presUnit, tempUnit, massUnit, coefUnit;
        if (units === "imperial") {
            presUnit = "psi"
            tempUnit = "degf"
            massUnit = "lb/s"
            coefUnit = "Cv"
          } else if (units === "si") {
            presUnit = "mpa"
            tempUnit = "kelvin"
            massUnit = "kg/s"
            coefUnit = "Kv"
          } else {
            presUnit = "bar"
            tempUnit = "degc"
            massUnit = "kg/s"
            coefUnit = "Kv"
          }
        for (index = 0; index < state.loadCases.length; index++) {
            dispatch("convertPiValue", {newUnit: presUnit, index: index});
            dispatch("convertTiValue", {newUnit: tempUnit, index: index});
            dispatch("convertPeValue", {newUnit: presUnit, index: index});
            dispatch("convertFlowMassFlow", {newUnit: massUnit, index: index});
            dispatch("convertFlowFlowCoefficient", {newUnit: coefUnit, index: index});
            dispatch("convertTtValue", {newUnit: tempUnit, index: index});
            dispatch("convertPcValue", {newUnit: presUnit, index: index});
            dispatch("convertTcValue", {newUnit: tempUnit, index: index});
            commit("setFlowPiUnit", {value: presUnit, index: index});
            commit("setFlowTiUnit", {value: tempUnit, index: index});
            commit("setFlowPeUnit", {value: presUnit, index: index});
            commit("setFlowMassFlowUnit", {value: massUnit, index: index});
            commit("setFlowFlowCoefficientValue", {value: coefUnit, index: index});
            commit("setFlowTtUnit", {value: tempUnit, index: index});
            commit("setFlowPcUnit", {value: presUnit, index: index});
            commit("setFlowTcUnit", {value: tempUnit, index: index});
        }
        
    },
    setGlobalLevel({commit}, level) {
        commit("setFlowSettingsStoredLevel", level);
        localStorage.setItem("settingsLevel", level);
    },
    setGlobalFluid({commit}, fluid) {
        commit("setFlowSettingsStoredFluids", fluid);
        localStorage.setItem("settingsFluids", fluid);
    },
    setGlobalDetail({commit}, detail) {
        commit("setFlowSettingsStoredDetail", detail);
        localStorage.setItem("settingsDetail", detail);
    },
    storeFlowPiValue({ commit }, {value, index}) {
        commit("setFlowPiValue", {value: value, index: index});
    },
    storeFlowTiValue({ commit }, {value, index}) {
        commit("setFlowTiValue", {value: value, index: index});
    },
    storeFlowPeValue({ commit }, {value, index}) {
        commit("setFlowPeValue", {value: value, index: index});
    },
    storeFlowMassFlowValue({ commit }, {value, index}) {
        commit("setFlowMassFlowValue", {value: value, index: index});
    },
    storeFlowFlowCoefficientValue({ commit }, {value, index}) {
        commit("setFlowFlowCoefficientValue", {value: value, index: index})
    },
    storeFlowPiUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertPiValue", {newUnit: value, index: index});
        commit("setFlowPiUnit", {value: value, index: index});
    },
    storeFlowTiUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertTiValue", {newUnit: value, index: index});
        commit("setFlowTiUnit", {value: value, index: index});
    },
    storeFlowPeUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertPeValue", {newUnit: value, index: index});
        commit("setFlowPeUnit", {value: value, index: index});
    },
    storeFlowMassFlowUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertFlowMassFlow", {newUnit: value, index: index});
        commit("setFlowMassFlowUnit", {value: value, index: index});
    },
    storeFlowFlowCoefficientUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertFlowFlowCoefficient", {newUnit: value, index: index})
        commit("setFlowFlowCoefficientUnit", {value: value, index: index})
    },
    convertPiValue({ state, commit }, {newUnit, index}) {
        const pressure = state.loadCases[index].form.Pi.value;
        const prevUnit = state.loadCases[index].form.Pi.unit;
        commit("setFlowPiValue", {value: logic.input.pressure.convert(pressure,prevUnit,newUnit), index: index});
    },
    convertTiValue({ state, commit }, {newUnit, index}) {
        const temperature = state.loadCases[index].form.Ti.value;
        const prevUnit = state.loadCases[index].form.Ti.unit;
        commit("setFlowTiValue", {value: logic.input.temperature.convert(temperature,prevUnit,newUnit), index: index});
    },
    convertPeValue({ state, commit }, {newUnit, index}) {
        const pressure = state.loadCases[index].form.Pe.value;
        const prevUnit = state.loadCases[index].form.Pe.unit;
        commit("setFlowPeValue", {value: logic.input.pressure.convert(pressure,prevUnit,newUnit), index: index});
    },
    convertFlowMassFlow({ state, commit }, {newUnit, index}) {
        const massflow = state.loadCases[index].form.mdot.value;
        const prevUnit = state.loadCases[index].form.mdot.unit;
        commit("setFlowMassFlowValue", {value: logic.input.mdot.convert(massflow,prevUnit,newUnit), index: index});
    },
    convertFlowFlowCoefficient({ state, commit }, {newUnit, index}) {
        const flowCoefficient = state.loadCases[index].form.flowCoefficient.value
        const prevUnit = state.loadCases[index].form.flowCoefficient.unit
        commit("setFlowFlowCoefficientValue", {value: logic.input.flow.flowCoefficient.convert(flowCoefficient,prevUnit,newUnit), index: index})
    },
    storeFlowTtValue({ commit }, {value, index}) {
        commit("setFlowTtValue", {value: value, index: index});
    },
    storeFlowPcValue({ commit }, {value, index}) {
        commit("setFlowPcValue", {value: value, index: index});
    },
    storeFlowTcValue({ commit }, {value, index}) {
        commit("setFlowTcValue", {value: value, index: index});
    },
    storeFlowTtUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertTtValue", {newUnit: value, index: index});
        commit("setFlowTtUnit", {value: value, index: index});
    },
    storeFlowPcUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertPcValue", {newUnit: value, index: index});
        commit("setFlowPcUnit", {value: value, index: index});
    },
    storeFlowTcUnit({ commit, dispatch }, {value, index}) {
        dispatch("convertTcValue", {newUnit: value, index: index});
        commit("setFlowTcUnit", {value: value, index: index});
    },
    convertTtValue({ state, commit }, {newUnit, index}) {
        const temperature = state.loadCases[index].form.Tt.value;
        const prevUnit = state.loadCases[index].form.Tt.unit;
        commit("setFlowTtValue", {value: logic.input.temperature.convert(temperature,prevUnit,newUnit), index: index});
    },
    convertPcValue({ state, commit }, {newUnit, index}) {
        const pressure = state.loadCases[index].form.Pc.value;
        const prevUnit = state.loadCases[index].form.Pc.unit;
        commit("setFlowPcValue", {value: logic.input.pressure.convert(pressure,prevUnit,newUnit), index: index});
    },
    convertTcValue({ state, commit }, {newUnit, index}) {
        const temperature = state.loadCases[index].form.Tc.value;
        const prevUnit = state.loadCases[index].form.Tc.unit;
        commit("setFlowTcValue", {value: logic.input.temperature.convert(temperature,prevUnit,newUnit), index: index});
    },
    async storeFlowInletStandard({commit}, { standard = "DIN", size = null, norm = null, index }) {
        return await new Promise((resolve, reject) => {
            commit("setFlowInletStandard", {value: standard, index: index});
            if (standard === "ANSI") {
                commit("setFlowInletSizeList", {value: ANSI, index: index});
                commit("setFlowInletNormList", {value: ANSI[0].schedule, index: index});
                if (size === null) {
                    size = "1/2";
                }
                commit("setFlowInletSize", {value: size, index: index});
                if (norm === null) {
                    norm = "40";
                }
                commit("setFlowInletNorm", {value: norm, index: index});
                resolve({ message: "Inlet sizing set to ANSI" });
            } else if (standard === "DIN") {
                commit("setFlowInletSizeList", {value: DIN, index: index});
                commit("setFlowInletNormList", {value: DIN[0].PN, index: index});
                if (size === null) {
                    size = 10;
                }
                commit("setFlowInletSize", {value: size, index: index});
                if (norm === null) {
                    norm = "40";
                }
                commit("setFlowInletNorm", {value: norm, index: index});
                resolve({ message: "Inlet sizing set to DIN" });
            } else if (standard === "COSTUM") {
                resolve({ message: "Inlet sizing set to COSTUM" });
            } else {
                reject({ message: "Incorrect sizing specified" });
            }
        });
    },
    async storeFlowOutletStandard({commit}, { standard = "DIN", size = null, norm = null, index }) {
        return await new Promise((resolve, reject) => {
            commit("setFlowOutletStandard", {value: standard, index: index});
            if (standard === "ANSI") {
                commit("setFlowOutletSizeList", {value: ANSI, index: index});
                commit("setFlowOutletNormList", {value: ANSI[0].schedule, index: index});
                if (size === null) {
                    size = "1/2";
                }
                commit("setFlowOutletSize", {value: size, index: index});
                if (norm === null) {
                    norm = "40";
                }
                commit("setFlowOutletNorm", {value: norm, index: index});
                resolve({ message: "Inlet sizing set to ANSI" });
            } else if (standard === "DIN") {
                commit("setFlowOutletSizeList", {value: DIN, index: index});
                commit("setFlowOutletNormList", {value: DIN[0].PN, index: index});
                if (size === null) {
                    size = 10;
                }
                commit("setFlowOutletSize", {value: size, index: index});
                if (norm === null) {
                    norm = "40";
                }
                commit("setFlowOutletNorm", {value: norm, index: index});
                resolve({ message: "Inlet sizing set to DIN" });
            } else if (standard === "COSTUM") {
                resolve({ message: "Inlet sizing set to COSTUM" });
            } else {
                reject({ message: "Incorrect sizing specified" });
            }
        });
    },
    async storeFlowInletSize ({ state, commit }, {size, index}) {
        return await new Promise((resolve, reject) => {
            commit("setFlowInletSize", {value: size, index: index});
            var NormList;
            if (state.loadCases[index].form.inletSizing.standard === "ANSI") {
                NormList = ANSI.filter((item) => item.NPS === size);
                commit("setFlowInletNormList", {value: NormList[0].schedule, index: index});
                if (NormList[0].schedule[state.loadCases[index].form.inletSizing.norm] === undefined) {
                    commit("setFlowInletNorm", {value: "40", index: index});
                }
                resolve({ message: "Sizing specified" });
            } else if (state.loadCases[index].form.inletSizing.standard === "DIN") {
                NormList = DIN.filter((item) => item.DIN === size);
                commit("setFlowInletNormList", {value: NormList[0].PN, index: index});
                if (NormList[0].PN[state.loadCases[index].form.inletSizing.norm] === undefined) {
                    commit("setFlowInletNorm", {value: "40", index: index});
                }
                resolve({ message: "Sizing specified" });
            } else {
                reject({ message: "Incorrect sizing specified" });
            }
        });
      },
      async storeFlowOutletSize ({ state, commit }, {size, index}) {
        return await new Promise((resolve, reject) => {
            commit("setFlowOutletSize", {value: size, index: index});
            var NormList;
            if (state.loadCases[index].form.outletSizing.standard === "ANSI") {
                NormList = ANSI.filter((item) => item.NPS === size);
                commit("setFlowOutletNormList", {value: NormList[0].schedule, index: index});
                if (NormList[0].schedule[state.loadCases[index].form.outletSizing.norm] === undefined) {
                    commit("setFlowOutletNorm", {value: "40", index: index});
                }
                resolve({ message: "Sizing specified" });
            } else if (state.loadCases[index].form.outletSizing.standard === "DIN") {
                NormList = DIN.filter((item) => item.DIN === size);
                commit("setFlowOutletNormList", {value: NormList[0].PN, index: index});
                if (NormList[0].PN[state.loadCases[index].form.outletSizing.norm] === undefined) {
                    commit("setFlowOutletNorm", {value: "40", index: index});
                }
                resolve({ message: "Sizing specified" });
            } else {
                reject({ message: "Incorrect sizing specified" });
            }
        });
      },
}

const mutations = {
    openFlowRequest: (state, {type, index}) => {
        state.request.index = index
        state.request.running = true,
        state.request.type = type
        state.request.message = null
    },
    closeFlowRequest: (state) => {
        state.request.running = false
        state.request.type = null
    },
    setFlowMessage: (state, message) => {
        state.request.message = message
    },
    setFlowHelpActive: (state, active) => state.help.active = active,
    setFlowLoadCases: (state, loadCases) => state.loadCases = loadCases,
    setFlowLoadCaseResultType: (state, {value, index}) => state.loadCases[index].selected = value,
    setFlowPiValue: (state, {value, index}) => state.loadCases[index].form.Pi.value = value,
    setFlowTiValue: (state, {value, index}) => state.loadCases[index].form.Ti.value = value,
    setFlowPeValue: (state, {value, index}) => state.loadCases[index].form.Pe.value = value,
    setFlowMassFlowValue: (state, {value, index}) => state.loadCases[index].form.mdot.value = value,
    setFlowFlowCoefficientValue: (state, {value, index}) => state.loadCases[index].form.flowCoefficient.value = value,
    setFlowPiUnit: (state, {value, index}) => state.loadCases[index].form.Pi.unit = value,
    setFlowTiUnit: (state, {value, index}) => state.loadCases[index].form.Ti.unit = value,
    setFlowPeUnit: (state, {value, index}) => state.loadCases[index].form.Pe.unit = value,
    setFlowMassFlowUnit: (state, {value, index}) => state.loadCases[index].form.mdot.unit = value,
    setFlowFlowCoefficientUnit: (state, {value, index}) => state.loadCases[index].form.flowCoefficient.unit = value,
    setFlowCooling: (state, {value, index}) => state.loadCases[index].cooling = value,
    setFlowTtValue: (state, {value, index}) => state.loadCases[index].form.Tt.value = value,
    setFlowPcValue: (state, {value, index}) => state.loadCases[index].form.Pc.value = value,
    setFlowTcValue: (state, {value, index}) => state.loadCases[index].form.Tc.value = value,
    setFlowTtUnit: (state, {value, index}) => state.loadCases[index].form.Tt.unit = value,
    setFlowPcUnit: (state, {value, index}) => state.loadCases[index].form.Pc.unit = value,
    setFlowTcUnit: (state, {value, index}) => state.loadCases[index].form.Tc.unit = value,
    setFlowSizing: (state, {value, index}) => state.loadCases[index].sizing = value,
    setFlowInletStandard: (state, {value, index}) => state.loadCases[index].form.inletSizing.standard = value,
    setFlowInletSizeList: (state, {value, index}) => state.loadCases[index].form.inletSizing.sizeList = value,
    setFlowInletNormList: (state, {value, index}) => state.loadCases[index].form.inletSizing.normList = value,
    setFlowInletSize: (state, {value, index}) => state.loadCases[index].form.inletSizing.size = value,
    setFlowInletNorm: (state, {value, index}) => state.loadCases[index].form.inletSizing.norm = value,
    setFlowOutletStandard: (state, {value, index}) => state.loadCases[index].form.outletSizing.standard = value,
    setFlowOutletSizeList: (state, {value, index}) => state.loadCases[index].form.outletSizing.sizeList = value,
    setFlowOutletNormList: (state, {value, index}) => state.loadCases[index].form.outletSizing.normList = value,
    setFlowOutletSize: (state, {value, index}) => state.loadCases[index].form.outletSizing.size = value,
    setFlowOutletNorm: (state, {value, index}) => state.loadCases[index].form.outletSizing.norm = value,
    setFlowResult: (state, {result, index}) => state.loadCases[index].result = result,
    setFlowSettingsUnits: (state, value) => (state.settings.form.units = value),

    setFlowDesignSelected: (state, value) => state.design.selected = value,

    setFlowSettingsLevel: (state, value) => (state.settings.form.level = value),
    setFlowSettingsFluids: (state, value) =>
        (state.settings.form.fluids = value),
    setFlowSettingsDetail: (state, value) =>
        (state.settings.form.detail = value),
    setFlowSettingsStoredUnits: (state, value) =>
        (state.settings.stored.units = value),
    setFlowSettingsStoredLevel: (state, value) =>
        (state.settings.stored.level = value),
    setFlowSettingsStoredFluids: (state, value) =>
        (state.settings.stored.fluids = value),
    setFlowSettingsStoredDetail: (state, value) =>
        (state.settings.stored.detail = value),
}

export default {
    state,
    getters,
    actions,
    mutations
}