const company = {
    min: 3,
    max: 64,
    minLength(input) {
      input = String(input)
      return input.length >= company.min
    },
    maxLength(input) {
      input = String(input)
      return input.length <= company.max
    },
    validate(input) {
      return company.minLength(input) && company.maxLength(input);
    },
}

export default company