import session from '../session'

const project = {
    store(project_id, data) {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return session.put(`/sonic/user/project/${project_id}/`, data)
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return session.put(`/sonic/token/project/${project_id}/?device-token=${clientToken}`, data)
        }
    },
    async calculate(project_id) {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return await session.post(`/sonic/user/project/${project_id}/calculate/`, {})
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return await session.post(`/sonic/token/project/${project_id}/calculate/?device-token=${clientToken}`, {})
        }
    },
    results(project_id) {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return session.get(`/sonic/user/project/${project_id}/calculate/`)
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return session.get(`/sonic/token/project/${project_id}/calculate/?device-token=${clientToken}`)
        }
    },
    link(project_id) {
        const accessToken = localStorage.getItem("accessToken") || null;
        if (accessToken) {
            return session.post(`${process.env.VUE_APP_API_URL}/sonic/user/project/${project_id}/link/`, {})
        } else {
            const clientToken = localStorage.getItem("clientToken") || null;
            return session.post(`${process.env.VUE_APP_API_URL}/sonic/token/project/${project_id}/link/?device-token=${clientToken}`, {})
        }
    }
}

export default project