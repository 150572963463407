const Length = {
  units: {
    metric: "m",
    imperial: "ft",
    si: "mm",
    mm: "mm",
    inch: "inch",
    m: "m",
    fallback: "metric"
  },
  m: {
    min: 0,
    max: 50,
  },
  ft: {
    min: 0,
    max: 165,
  },
  mm: {
    min: 0,
    max: 50000,
  },
  validate(length, unit) {
    if (isNaN(parseFloat(length)) === true) {
      return false;
    }
    if (unit === "m") {
      return (
        parseFloat(length) > Length.m.min &&
        parseFloat(length) < Length.m.max
      );
    } else if (unit === "ft") {
      return (
        parseFloat(length) > Length.ft.min &&
        parseFloat(length) < Length.ft.max
      );
    } else if (unit === "mm") {
      return (
        parseFloat(length) > Length.mm.min &&
        parseFloat(length) < Length.mm.max
      );
    }
  },
  getLowerBoundery(units) {
    if (units === "ft") {
      return Length.ft.min;
    } else if (units === "mm") {
      return Length.mm.min;
    } else {
      return Length.m.min;
    }
  },
  getUpperBoundery(units) {
    if (units === "ft") {
      return Length.ft.max;
    } else if (units === "mm") {
      return Length.mm.max;
    } else {
      return Length.m.max;
    }
  },
}

export default Length