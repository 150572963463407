import flowCoefficient from './flowCoefficient'
import din from './din'
import ansi from './ansi'
import inletDiameter from './partDiameter'

const elbow = {
  validate (part) {
    if (flowCoefficient.validate(part.type, part.flowValue, part.flowUnit) === true) {
      if (part.inletSizing === "DIN") {
        return din.validate(part.inletSizing)
      } else if (part.inletSizing === "ANSI") {
        return ansi.validate(part.inletSizing)
      } else if (part.inletSizing === "COSTUM") {
        return inletDiameter.validate(part.inletSize, part.inletSizeUnit)
      }
    }
    return false  
  }
}

export default elbow