import Vue from 'vue'
import i18n from '@/i18n';

Vue.use(i18n)

const visual = {
  pressure: {
    id: 'pressure-chart',
    backgroundColor: 'rgb(0,121,213,1)',
    borderColor: 'rgb(0,121,213,0.6)',
    lineFill: false,
    lineTension: 0.1,
    responsive: true
  },
  temperature: {
    id: 'temperature-chart',
    backgroundColor: 'rgb(255,51,51,1)',
    borderColor: 'rgb(255,51,51,0.6)',
    lineFill: false,
    lineTension: 0.1,
    responsive: true,
  },
  mach: {
    id: 'mach-chart',
    backgroundColor: 'rgb(39,181,58,1)',
    borderColor: 'rgb(39,181,58,0.6)',
    lineFill: false,
    lineTension: 0.1,
    responsive: true,
  },
  velocity: {
    id: 'velocity-chart',
    backgroundColor: 'rgb(153,0,153,1)',
    borderColor: 'rgb(153,0,153,0.6)',
    lineFill: false,
    lineTension: 0.1,
    responsive: true,
  },
  vaporQuality: {
    id: 'vaporQuality-chart',
    backgroundColor: 'rgb(242,129,255,1)',
    borderColor: 'rgb(242,129,255,0.6)',
    lineFill: false,
    lineTension: 0.1,
    responsive: true,
  },
  mdot: {
    id: 'mdot-chart',
    backgroundColor: 'rgb(30,138,134,1)',
    borderColor: 'rgb(30,138,134,0.6)',
    lineFill: false,
    lineTension: 0.1,
    responsive: true,
  },
  parseParts(parts) {
    var list = []
    parts.forEach(item => {
      if (item === 'Inlet') {
        list.push(i18n.t('sonic.project.visual.graph.inlet'))
      } else if (item === 'Outlet') {
        list.push(i18n.t('sonic.project.visual.graph.outlet'))
      } else {
        list.push(item)
      }
    })
    return list
  }
}

export default visual