const partDiameter = {
  units: {
    metric: "mm",
    imperial: "inch",
    si: "m",
    mm: "mm",
    inch: "inch",
    m: "m",
    fallback: "metric"
  },
  mm: {
    min: 0,
    max: 5000,
  },
  inch: {
    min: 0,
    max: 200,
  },
  si: {
    min: 0,
    max: 5,
  },
  validate(part, unit) {
    if (isNaN(parseFloat(part.sizingInlet)) === true) {
      return false;
    }
    if (unit === "mm") {
      return (
        parseFloat(part.sizingInlet) > partDiameter.mm.min &&
        parseFloat(part.sizingInlet) < partDiameter.mm.max
      );
    } else if (unit === "inch") {
      return (
        parseFloat(part.sizingInlet) > partDiameter.inch.min &&
        parseFloat(part.sizingInlet) < partDiameter.inch.max
      );
    } else if (unit === "m") {
      return (
        parseFloat(part.sizingInlet) > partDiameter.m.min &&
        parseFloat(part.sizingInlet) < partDiameter.m.max
      );
    }
  },
};

export default partDiameter;
