import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger';
import sonic from './modules/sonic'
import loss from './modules/loss'
import flow from './modules/flow'
import props from './modules/props'
import auth from './modules/auth'
import pages from './modules/pages'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        sonic,
        loss,
        flow,
        auth,
        pages,
        props
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
})