import emailForm from './email'
import passwordForm from './password'

const login = {
    email: emailForm,
    password: passwordForm,
    validate(input1, input2) {
        return emailForm.validate(input1) && passwordForm.validate(input2)
    }
}

export default login