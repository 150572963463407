import session from '../session'

const clientToken = {
    register() {
        return session.get(`/client/token/?api-key=${process.env.VUE_APP_API_KEY}`)
    },
    async validate(token) {
        return new Promise((resolve,reject) => {
            const data = {
                device_token: token
            }
            session.post('/client/token/', data)
            .then((response) => {
                if (response.data.device_token === token) {
                    resolve(response.data.device_token)
                } else {
                    reject(response)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}

export default clientToken