import session from "../session";

const user = {
  register(data) {
    return session.post('/auth/users/', data);
  },
  login(data) {
    return session.post('/auth/jwt/create/', data);
  },
  logout(data) {
    return session.post('/user/logout/blacklist/', data);
  },
  fetchUserData() {
    return session.get('/auth/users/')
  },
  async refresh(refreshToken) {
    return new Promise((resolve,reject) => {
      if (refreshToken) {
        session.post(`${process.env.VUE_APP_API_URL}/auth/jwt/refresh/`, {refresh: refreshToken})
        .then((response) => {
            resolve(response)
        })
        .catch((error) => {
            reject(error)
        })
      } else {
        reject("No accessToken in localStorage")
      }
    })
  },
  async validate(accessToken) {
    return new Promise((resolve,reject) => {
      if (accessToken) {
        session.post(`${process.env.VUE_APP_API_URL}/auth/jwt/verify/`, {token: accessToken})
        .then((response) => {
            resolve(response)
        })
        .catch((error) => {
            reject(error)
        })
      } else {
        reject("No accessToken in localStorage")
      }
        
    })
  },
  async activateAccount(data) {
    return new Promise((resolve, reject) => {
      session.post(`${process.env.VUE_APP_API_URL}/auth/users/activation/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  async activationCodeResend(data) {
    return new Promise((resolve, reject) => {
      session.post(`${process.env.VUE_APP_API_URL}/auth/users/resend_activation/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  async sendPasswordReset(data) {
    return new Promise((resolve, reject) => {
      session.post(`${process.env.VUE_APP_API_URL}/auth/users/reset_password/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  async resetPassword(data) {
    return new Promise((resolve, reject) => {
      session.post(`${process.env.VUE_APP_API_URL}/auth/users/reset_password_confirm/`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
};

export default user;
