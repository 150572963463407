// const sonic = require('./pages/sonic.json')
// const loss = require('./pages/loss.json')
// const flow = require('./pages/flow.json')
// const props = require('./pages/props.json')

function loadLocaleMessages() {
  const locales = require.context(
    "./pages",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}


export default loadLocaleMessages()