const mdot = {
    units: {
        metric: "kg/s",
        imperial: "lb/s",
        kg_s: "kg/s",
        lb_s: "lb/s",
        ton_h: "ton/hour"
    },
    kg_s: {
        min: 0,
        max: null
    },
    lb_s: {
        min: 0,
        max: null
    },
    ton_h: {
        min: 0,
        max: null
    },
    convert (value, prevUnit, newUnit) {
        if (isNaN(parseFloat(value)) === true) {
            return value;
        }
        value = parseFloat(value);
        if (prevUnit.toLowerCase() === "kg/s") {
            if (newUnit.toLowerCase() === "lb/s") {
              value *= 2.20462262;
            } else if (newUnit === "ton/h") {
              value *= 3.6;
            }
        } else if (prevUnit.toLowerCase() === "lb/s") {
            if (newUnit.toLowerCase() === "kg/s") {
              value *= 0.45359237;
            } else if (newUnit === "ton/h") {
              value *= 1.632932532;
            }
        } else if (prevUnit.toLowerCase() === "ton/h") {
            if (newUnit.toLowerCase() === "kg/s") {
              value *= 0.27777777777;
            } else if (newUnit === "lb/s") {
              value *= 0.61239517273;
            }
        }
        return Math.round(value * 100) / 100;
    },
    validate (value, unit) {
        if (isNaN(parseFloat(value)) === true) {
          return false
        }
        if (unit.toLowerCase() === "kg/s") {
            if (mdot.kg_s.min && mdot.kg_s.max) {
                return (
                    parseFloat(value) > mdot.kg_s.min &&
                    parseFloat(value) < mdot.kg_s.max
                );
            } else if (mdot.kg_s.min) {
                return (
                    parseFloat(value) > mdot.kg_s.min
                );
            } else if (mdot.kg_s.max) {
                return (
                    parseFloat(value) < mdot.kg_s.max
                );
            } else {
                return !!parseFloat(value)
            }        
        } else if (unit.toLowerCase() === "lb/s") {
            if (mdot.lb_s.min && mdot.lb_s.max) {
                return (
                    parseFloat(value) > mdot.lb_s.min &&
                    parseFloat(value) < mdot.lb_s.max
                );
            } else if (mdot.lb_s.min) {
                return (
                    parseFloat(value) > mdot.lb_s.min
                );
            } else if (mdot.lb_s.max) {
                return (
                    parseFloat(value) < mdot.lb_s.max
                );
            } else {
                return !!parseFloat(value)
            } 
        } else if (unit.toLowerCase() === "ton/h") {
            if (mdot.ton_h.min && mdot.ton_h.max) {
                return (
                    parseFloat(value) > mdot.ton_h.min &&
                    parseFloat(value) < mdot.ton_h.max
                );
            } else if (mdot.lb_s.min) {
                return (
                    parseFloat(value) > mdot.lb_s.min
                );
            } else if (mdot.ton_h.max) {
                return (
                    parseFloat(value) < mdot.ton_h.max
                );
            } else {
                return !!parseFloat(value)
            } 
        } else {
          return false
        }
    },
    lower (value, unit, inletValue, inletUnit) {
        if (isNaN(parseFloat(value)) === true || isNaN(parseFloat(inletValue)) === true) {
          return false
        }
        if (unit !== inletUnit) {
          inletValue = mdot.convert(inletValue, inletUnit, unit)
        }
        return parseFloat(value) < parseFloat(inletValue)
      },
      getUnits(units) {
        if (units in mdot.units){
          return mdot.units[units]
        } else {
          return mdot.getUnits(units=mdot.units.fallback)
        }
    }
}

export default mdot