const projectDescription = {
    min: 10,
    max: 255,
    minLength(description) {
      description = String(description)
      return description.length >= projectDescription.min
    },
    maxLength(description) {
      description = String(description)
      return description.length <= projectDescription.max
    },
    validate(description) {
      return projectDescription.minLength(description) && projectDescription.maxLength(description);
    },
  };

export default projectDescription