import flowCoefficient from './flowCoefficient'

const parts = {
    flowCoefficient: flowCoefficient,
    validate(items) {
        var valid = []
        items.forEach((item) => {
            valid.push(flowCoefficient.validate(item.flowValue,item.flowUnit))
        })
        return valid.every((item) => item === true)
    }
}

export default parts