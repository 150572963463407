import auth from './auth'
import sonic from './sonic'
import loss from './loss'
import props from './props'
import flow from './flow'
import session from './session'

const request = {
    session: session,
    auth: auth,
    sonic: sonic,
    loss: loss,
    flow: flow,
    props: props
}

export default request