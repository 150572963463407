import input from './input'
import output from './output'
import request from './request'

const logic = {
    input: input,
    output: output,
    request: request
}

export default logic