const flowCoefficient = {
  units: {
    metric: "kv",
    imperial: "cv",
    dimensionless: "k",
    kv: "kv",
    cv: "cv",
    k: "k",
    fallback: "metric"
  },
  validate(type, value, unit) {
    if (isNaN(parseFloat(value))) {
      return false
    }
    if (type === "pipe") {
      return false
    } else if (type === "valve") {
      return parseFloat(value) > 0 && ["kv","cv"].includes(unit)
    } else if (type === "elbow") {
      return parseFloat(value) > 0 && ["k"].includes(unit)
    }
  }
}

export default flowCoefficient