const flowCoefficient = {
    units: {
      metric: "kv",
      imperial: "cv",
      dimensionless: "k",
      kv: "kv",
      cv: "cv",
      k: "k",
      fallback: "metric"
    },
    kv: {
      min: 0,
      max: null
    },
    cv: {
      min: 0,
      max: null
    },
    k: {
      min: 0,
      max: null
    },
    convert (value, prevUnit, newUnit) {
      if (isNaN(parseFloat(value)) === true) {
          return value;
      }
      value = parseFloat(value);
      if (prevUnit.toLowerCase() === "kv") {
          if (newUnit.toLowerCase() === "cv") {
            value *= 1.1560992283536566;
          } 
      } else if (prevUnit.toLowerCase() === "cv") {
          if (newUnit.toLowerCase() === "kv") {
            value *= 0.86497765544;
          } 
      } 
      return Math.round(value * 100) / 100;
  },
    validate(value, unit) {
      if (isNaN(parseFloat(value))) {
        return false
      }
      if (unit.toLowerCase() === "kv") {
        if (flowCoefficient.kv.min && flowCoefficient.kv.max) {
          return (
            parseFloat(value) > flowCoefficient.kv.min &&
            parseFloat(value) < flowCoefficient.kv.max
          );
        } else if (flowCoefficient.kv.min) {
          return parseFloat(value) > flowCoefficient.kv.min
        } else if (flowCoefficient.kv.max) {
          return parseFloat(value) < flowCoefficient.kv.max
        } else {
          return !!(parseFloat(value))
        }
      } else if (unit.toLowerCase() === "cv") {
        if (flowCoefficient.cv.min && flowCoefficient.cv.max) {
          return (
            parseFloat(value) > flowCoefficient.cv.min &&
            parseFloat(value) < flowCoefficient.cv.max
          );
        } else if (flowCoefficient.cv.min) {
          return parseFloat(value) > flowCoefficient.cv.min
        } else if (flowCoefficient.cv.max) {
          return parseFloat(value) < flowCoefficient.cv.max
        } else {
          return !!(parseFloat(value))
        }
      } else if (unit.toLowerCase() === "k") {
        if (flowCoefficient.cv.min && flowCoefficient.cv.max) {
          return (
            parseFloat(value) > flowCoefficient.cv.min &&
            parseFloat(value) < flowCoefficient.cv.max
          );
        } else if (flowCoefficient.cv.min) {
          return parseFloat(value) > flowCoefficient.cv.min
        } else if (flowCoefficient.cv.max) {
          return parseFloat(value) < flowCoefficient.cv.max
        } else {
          return !!(parseFloat(value))
        }
      } else {
        return false
      }
    }
  }
  
  export default flowCoefficient