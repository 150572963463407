import flowCoefficient from './flowCoefficient'
import din from './din'
import ansi from './ansi'
import partDiameter from './partDiameter'

const valve = {
  validate(part) {
    if (flowCoefficient.validate(part.type, part.flowValue, part.flowUnit) === true) {
      if (part.inletSizing === "DIN") {
        if (din.validate(part.inletSizing)) {
          if (part.outletSizing === "DIN") {
            return din.validate(part.outletSizing)
          } else if (part.outletSizing === "ANSI") {
            return ansi.validate(part.outletSizing)
          } else if (part.outletSizing === "COSTUM") {
            return partDiameter.validate(part.outletSize, part.outletSizeUnit)
          }
        }
      } else if (part.inletSizing === "ANSI") {
        if (ansi.validate(part.inletSizing)) {
          if (part.outletSizing === "DIN") {
            return din.validate(part.outletSizing)
          } else if (part.outletSizing === "ANSI") {
            return ansi.validate(part.outletSizing)
          } else if (part.outletSizing === "COSTUM") {
            return partDiameter.validate(part.outletSize, part.outletSizeUnit)
          }
        }
      } else if (part.inletSizing === "COSTUM") {
        if (partDiameter.validate(part.inletSize, part.inletSizeUnit)) {
          if (part.outletSizing === "DIN") {
            return din.validate(part.outletSizing)
          } else if (part.outletSizing === "ANSI") {
            return ansi.validate(part.outletSizing)
          } else if (part.outletSizing === "COSTUM") {
            return partDiameter.validate(part.outletSize, part.outletSizeUnit)
          }
        }
      }
    }
    return false
  }
}

export default valve