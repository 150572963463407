import pressure from './pressure'
import temperature from './temperature'
import enthalpy from './enthalpy'

const properties = {
    pressure: pressure,
    temperature: temperature,
    enthalpy: enthalpy,
    calculateable(P,T,H) {
        const validPressure = pressure.validate(P.value, P.unit)
        const validTemperature = temperature.validate(T.value, T.unit)
        const validEnthalpy = enthalpy.validate(H.value, H.unit)
        if ([validPressure,validTemperature,validEnthalpy].filter(Boolean).length == 2) {
            return true
        } else {
            return false
        }
    }
}

export default properties