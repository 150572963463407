const pressure = {
  units: {
    metric: "bar",
    imperial: "psi",
    si: "mpa",
    bar: "bar",
    psi: "psi",
    mpa: "mpa",
    fallback: "metric"
  },
  bar: {
    min: 0,
    max: 350
  },
  psi: {
    min: 0,
    max: 5100
  },
  mpa: {
    min: 0,
    max: 35
  },
  convert (value, prevUnit, newUnit) {
    if (isNaN(parseFloat(value)) === true) {
      return value;
    }
    value = parseFloat(value);
    if (prevUnit.toLowerCase() === "bar") {
      if (newUnit.toLowerCase() === "psi") {
        value *= 14.503773773;
      } else if (newUnit === "mpa") {
        value *= 0.1;
      }
    } else if (prevUnit.toLowerCase() === "psi") {
      if (newUnit === "bar") {
        value *= 0.06894757293;
      } else if (newUnit === "mpa") {
        value *= 0.006894757293;
      }
    } else if (prevUnit.toLowerCase() === "mpa") {
      if (newUnit.toLowerCase() === "bar") {
        value *= 10;
      } else if (newUnit === "psi") {
        value *= 145.03773773;
      }
    }
    return Math.round(value * 100) / 100;
  },
  validate (value, unit) {
    if (isNaN(parseFloat(value)) === true) {
      return false
    }
    if (unit.toLowerCase() === "bar") {
      return (
        parseFloat(value) > pressure.bar.min &&
        parseFloat(value) < pressure.bar.max
      );
    } else if (unit.toLowerCase() === "psi") {
      return (
        parseFloat(value) > pressure.psi.min &&
        parseFloat(value) < pressure.psi.max
      );
    } else if (unit.toLowerCase() === "mpa") {
      return (
        parseFloat(value) > pressure.mpa.min &&
        parseFloat(value) < pressure.mpa.max
      );
    } else {
      return false
    }
  },
  lower (value, unit, inletValue, inletUnit) {
    if (isNaN(parseFloat(value)) === true || isNaN(parseFloat(inletValue)) === true) {
      return false
    }
    if (unit !== inletUnit) {
      inletValue = pressure.convert(inletValue, inletUnit, unit)
    }
    return parseFloat(value) < parseFloat(inletValue)
  },
  getUnits(units) {
    if (units in pressure.units){
      return pressure.units[units]
    } else {
      return pressure.getUnits(units=pressure.units.fallback)
    }
  }
}

export default pressure