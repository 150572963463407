<template>
  <div id="lang">
    <b-dropdown 
      variant="primary" 
      right text="Right align"
      class="mr-2"
      >
      <template v-slot:button-content>
        <flag :iso="getFlag($i18n.locale)" v-bind:squared="false" />
      </template>
      <b-dropdown-item
        v-for="language in languages"
        :key="language.language"
        @click="changeLocale(language.language)"
      >
        <flag :iso="language.flag" v-bind:squared="false" />
        {{language.title}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "lang",
  data() {
    return {
      languages: [
        { flag: "gb", language: "en-GB", title: "English" },
        { flag: "nl", language: "nl-NL", title: "Nederlands" },
        { flag: "de", language: "de-DE", title: "Deutsche" },
      ],
    };
  },
  methods: {
    getFlag(language) {
      return this.languages.filter(function (item) {
        return item.language === language;
      })[0].flag;
    },
    changeLocale(locale) {
      
      this.$i18n.locale = locale;
      if (locale === "en-GB" || locale === "en-US") {
        this.$moment.locale('en')
      } else if (locale === "nl-NL") {
        this.$moment.locale('nl')
      } else if (locale === 'de-DE') {
        this.$moment.locale('de')
      } else {
        this.$moment.locale('en')
      }
      
    },
  },
};
</script>

<style>
</style>