const ANSI = [
    { 
        NPS: '1/2',
        schedule: {
            '40S': [21.3, 2.77],
            '40': [21.3, 2.77],
            '80S': [21.3, 3.73],
            '80': [21.3, 3.73],
            '160': [21.3, 4.78],
            'XXS': [21.3, 7.47],
        }
    },
    {
        NPS: '3/4',
        schedule: {
            '40S': [26.7, 2.87],
            '40': [26.7, 2.87],
            '80S': [26.7, 3.91],
            '80': [26.7, 3.91],
            '160': [26.7, 5.56],
            'XXS': [26.7, 7.82],
        }
    },
    {
        NPS: '1',
        schedule: {
            '40S': [33.4, 3.38],
            '40': [33.4, 3.38],
            '80S': [33.4, 4.55],
            '80': [33.4, 4.55],
            '160': [33.4, 6.35],
            'XXS': [33.4, 9.09],
        }
    },
    {
        NPS: '1 1/4',
        schedule: {
            '40S': [42.2, 3.56],
            '40': [42.2, 3.56],
            '80S': [42.2, 4.85],
            '80': [42.2, 4.85],
            '160': [42.2, 6.35],
            'XXS': [42.2, 9.70],
        }
    },
    {
        NPS: '1 1/2',
        schedule: {
            '40S': [48.3, 3.68],
            '40': [48.3, 3.68],
            '80S': [48.3, 5.08],
            '80': [48.3, 5.08],
            '160': [48.3, 7.14],
            'XXS': [48.3, 10.15],
        }
    },
    {
        NPS: '2',
        schedule: {
            '40S': [60.3, 3.91],
            '40': [60.3, 3.91],
            '80S': [60.3, 5.54],
            '80': [60.3, 5.54],
            '120': [60.3, 7.14],
            '160': [60.3, 8.74],
            'XXS': [60.3, 11.07],
        }
    },
    {
        NPS: '2 1/2',
        schedule: {
            '40S': [73.0, 5.16],
            '40': [73.0, 5.16],
            '80S': [73.0, 7.01],
            '80': [73.0, 7.01],
            '160': [73.0, 9.53],
            'XXS': [73.0, 14.02],
        }
    },
    {
        NPS: '3',
        schedule: {
            '40S': [88.9, 5.49],
            '40': [88.9, 5.49],
            '80S': [88.9, 7.62],
            '80': [88.9, 7.62],
            '160': [88.9, 11.13],
            'XXS': [88.9, 15.24],
        }
    },
    {
        NPS: '4',
        schedule: {
            '40S': [114.3, 6.02],
            '40': [114.3, 6.02],
            '80S': [114.3, 8.56],
            '80': [114.3, 8.56],
            '120': [114.3, 11.13],
            '160': [114.3, 13.49],
            'XXS': [114.3, 17.12],
        }
    },
    {
        NPS: '5',
        schedule: {
            '40S': [141.3, 6.55],
            '40': [141.3, 6.55],
            '80S': [141.3, 9.53],
            '80': [141.3, 9.53],
            '120': [141.3, 12.70],
            '160': [141.3, 15.88],
            'XXS': [141.3, 19.05],
        }
    },
    {
        NPS: '6',
        schedule: {
            '40S': [168.3, 7.11],
            '40': [168.3, 7.11],
            '80S': [168.3, 10.97],
            '80': [168.3, 10.97],
            '120': [168.3, 14.27],
            '160': [168.3, 18.26],
            'XXS': [168.3, 21.95],
        }
    },
    {
        NPS: '8',
        schedule: {
            '40S': [219.1, 8.18],
            '40': [219.1, 8.18],
            '60': [219.1, 10.31],
            '80S': [219.1, 12.70],
            '80': [219.1, 12.70],
            '100': [219.1, 15.09],
            '120': [219.1, 18.26],
            '140': [219.1, 20.62],
            '160': [219.1, 23.01],
            'XXS': [219.1, 22.23],
        }
    },
    {
        NPS: '10',
        schedule: {
            '40S': [273.0, 9.27],
            '40': [273.0, 9.27],
            '60': [273.0, 12.70],
            '80S': [273.0, 12.70],
            '80': [273.0, 15.09],
            '100': [273.0, 18.26],
            '120': [273.0, 21.44],
            '140': [273.0, 25.40],
            '160': [273.0, 28.58],
            'XXS': [273.0, 25.40],
        }
    },
    {
        NPS: '12',
        schedule: {
            '40S': [323.8, 9.53],
            '40': [323.8, 10.31],
            '60': [323.8, 14.27],
            '80S': [323.8, 12.70],
            '80': [323.8, 17.48],
            '100': [323.8, 21.44],
            '120': [323.8, 25.40],
            '140': [323.8, 28.58],
            '160': [323.8, 33.32],
            'XXS': [323.8, 25.40],
        }
    },
    {
        NPS: '14',
        schedule: {
            '40S': [355.6, 9.53],
            '40': [355.6, 11.13],
            '60': [355.6, 15.09],
            '80S': [355.6, 12.70],
            '80': [355.6, 19.05],
            '100': [355.6, 23.83],
            '120': [355.6, 27.79],
            '140': [355.6, 31.75],
            '160': [355.6, 35.71],
        }
    },
    {
        NPS: '16',
        schedule: {
            '40S': [406.4, 9.53],
            '40': [406.4, 12.70],
            '60': [406.4, 16.66],
            '80S': [406.4, 12.70],
            '80': [406.4, 21.44],
            '100': [406.4, 26.19],
            '120': [406.4, 30.96],
            '140': [406.4, 36.53],
            '160': [406.4, 40.49],
        }
    },
    {
        NPS: '18',
        schedule: {
            '40S': [457.2, 9.53],
            '40': [457.2, 14.27],
            '60': [457.2, 19.05],
            '80S': [457.2, 12.70],
            '80': [457.2, 23.83],
            '100': [457.2, 29.36],
            '120': [457.2, 34.93],
            '140': [457.2, 39.67],
            '160': [457.2, 45.24],
        }
    },
    {
        NPS: '20',
        schedule: {
            '40S': [508.0, 9.53],
            '40': [508.0, 15.09],
            '60': [508.0, 20.62],
            '80S': [508.0, 12.70],
            '80': [508.0, 26.19],
            '100': [508.0, 32.54],
            '120': [508.0, 38.10],
            '140': [508.0, 44.45],
            '160': [508.0, 50.01],
        }
    },
    {
        NPS: '22',
        schedule: {
            '40S': [559.0, 9.53],
            '60': [559.0, 22.22],
            '80S': [559.0, 12.70],
            '80': [559.0, 28.60],
            '100': [559.0, 34.92],
            '120': [559.0, 41.28],
            '140': [559.0, 47.62],
            '160': [559.0, 53.98],
        }
    },
    {
        NPS: '24',
        schedule: {
            '40S': [610.0, 9.53],
            '40': [610.0, 17.48],
            '60': [610.0, 24.61],
            '80S': [610.0, 12.70],
            '80': [610.0, 30.96],
            '100': [610.0, 38.89],
            '120': [610.0, 46.02],
            '140': [610.0, 52.37],
            '160': [610.0, 59.54],
        }
    },
    {
        NPS: '26',
        schedule: {
            '10': [660.0, 7.92],
            '20': [660.0, 12.70],
            '30': [660.0, 15.88],
            '40S': [660.0, 9.53]
        }
    },
    {
        NPS: '28',
        schedule: {
            '10': [711.0, 7.92],
            '20': [711.0, 12.70],
            '30': [711.0, 15.88],
            '40S': [711.0, 9.53]
        }
    },
    {
        NPS: '30',
        schedule: {
            '10': [762.0, 7.92],
            '20': [762.0, 12.70],
            '30': [762.0, 15.88],
            '40S': [762.0, 9.53]
        }
    },
    {
        NPS: '32',
        schedule: {
            '10': [813.0, 7.92],
            '20': [813.0, 12.70],
            '30': [813.0, 15.88],
            '40S': [813.0, 9.53],
            '40': [813.0, 17.48]
        }
    },
    {
        NPS: '34',
        schedule: {
            '10': [863.0, 7.92],
            '20': [863.0, 12.70],
            '30': [863.0, 15.88],
            '40S': [863.0, 9.53],
            '40': [863.0, 17.48]
        }
    },
    {
        NPS: '36',
        schedule: {
            '10': [914.0, 7.92],
            '20': [914.0, 12.70],
            '30': [914.0, 15.88],
            '40S': [914.0, 9.53],
            '40': [914.0, 19.05]
        }
    }
]

export default ANSI