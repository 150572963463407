import registerForm from './register'
import loginForm from './login'
import activateForm from './activate'
import passwordForm from './passwordReset'

const user = {
    register: registerForm,
    login: loginForm,
    activate: activateForm,
    password: passwordForm
}

export default user