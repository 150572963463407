import Vue from "vue";
import store from "./store";
import router from "./router";
import i18n from './i18n';
import cookies from "vue-cookies";
import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import FlagIcon from 'vue-flag-icon';
import {
  BootstrapVue,
  BootstrapVueIcons,
} from "bootstrap-vue/dist/bootstrap-vue.esm";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

require('moment/locale/nl')
require('moment/locale/de')
require('moment/locale/en-gb')

Vue.use(cookies);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMoment, {
  moment,
});
Vue.use(FlagIcon);

const production = process.env.VUE_APP_PRODUCTION === "true" ? true : false
Vue.config.productionTip = !production;

new Vue({
  store,
  router,
  i18n,
  cookies,
  render: (h) => h(App),
}).$mount("#app");

