import axios from 'axios'

const email = {
    async emailFormat(input) {
        return new Promise((resolve) => {
            if (!input) {
                resolve(false)
            }
            var mailformat = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
            if(mailformat.test(String(input).toLowerCase())) {
                resolve(true)
            }
            else {
                resolve(false)
            }
        })
    },
    async unique(input) {
        return new Promise((resolve, reject) => {
            const data = {
                email: input
            }
            if (email.emailFormat(input) === false) {
                // console.log('test')
                resolve(false)
            }
            axios.post(`${process.env.VUE_APP_API_URL}/user/email/check/?api-key=${process.env.VUE_APP_API_KEY}`, data)
            .then((response) => {
                // console.log(response)
                if (response.data.used === false) {
                    resolve(!response.data.used)
                } else {
                    resolve(!response.data.used)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    validate(input) {
        return (email.emailFormat(input) && email.unique(input))
    },
}

export default email