const enthalpy = {
  units: {
    metric: "kg/kj",
    imperial: "btu/lb",
    kj_kg: "kg/kj",
    btu_lb: "btu/lb",
    fallback: "metric"
  },
  kj_kg: {
    min: 0,
    max: 3800
  },
  btu_lb: {
    min: 0,
    max: 1500
  },
  convert (value, prevUnit, newUnit) {
    if (isNaN(parseFloat(value)) === true) {
      return value;
    }
    value = parseFloat(value);
    if (prevUnit.toLowerCase() === "kj/kg") {
      if (newUnit.toLowerCase() === "btu/lb") {
        value *= 0.4299226139;
      }
    } else if (prevUnit.toLowerCase() === "btu/lb") {
      if (newUnit.toLowerCase() === "kj/kg") {
        value *= 2.326;
      }
    }
    return Math.round(value * 100) / 100;
  },
  validate(value, unit) {
    if (isNaN(parseFloat(value)) === true) {
      return false
    }
    if (unit.toLowerCase() === "kj/kg") {
      return (
        parseFloat(value) > enthalpy.kj_kg.min &&
        parseFloat(value) < enthalpy.kj_kg.max
      );
    } else if (unit.toLowerCase() === "btu/lb") {
      return (
        parseFloat(value) > enthalpy.btu_lb.min &&
        parseFloat(value) < enthalpy.btu_lb.max
      );
    } else {
      return false
    }
  },
  lower (value, unit, inletValue, inletUnit) {
    if (isNaN(parseFloat(value)) === true || isNaN(parseFloat(inletValue)) === true) {
      return false
    }
    if (unit !== inletUnit) {
      inletValue = enthalpy.convert(inletValue, inletUnit, unit)
    }
    return value < inletValue
  }
}

export default enthalpy