const temperature = {
  units: {
    metric: "degc",
    imperial: "degf",
    si: "kelvin",
    degc: "degc",
    degf: "degf",
    kelvin: "kelvin",
    fallback: "metric"
  },
  degc: {
    min: 0,
    max: 650
  },
  degf: {
    min: 32,
    max: 1200
  },
  kelvin: {
    min: 273.15,
    max: 925
  },
  convert (value, prevUnit, newUnit) {
    if (isNaN(parseFloat(value)) === true) {
      return value;
    }
    value = parseFloat(value);
    if (prevUnit.toLowerCase() === "degc") {
      if (newUnit === "degf") {
        value *= 9 / 5;
        value += 32;
      } else if (newUnit === "kelvin") {
        value += 273.15;
      }
    } else if (prevUnit.toLowerCase() === "degf") {
      if (newUnit === "degc") {
        value -= 32;
        value *= 5 / 9;
      } else if (newUnit.toLowerCase() === "kelvin") {
        value += 459.67;
        value *= 5 / 9;
      }
    } else if (prevUnit.toLowerCase() === "kelvin") {
      if (newUnit === "degc") {
        value -= 273.15;
      } else if (newUnit.toLowerCase() === "degf") {
        value *= 9 / 5;
        value -= 459.67;
      }
    }
    return Math.round(value * 100) / 100;
  },
  validate(value, unit) {
    if (isNaN(parseFloat(value)) === true) {
      return false
    }
    if (unit.toLowerCase() === "degc") {
      return (
        parseFloat(value) > temperature.degc.min &&
        parseFloat(value) < temperature.degc.max
      );
    } else if (unit.toLowerCase() === "degf") {
      return (
        parseFloat(value) > temperature.degf.min &&
        parseFloat(value) < temperature.degf.max
      );
    } else if (unit.toLowerCase() === "kelvin") {
      return (
        parseFloat(value) > temperature.kelvin.min &&
        parseFloat(value) < temperature.kelvin.max
      );
    } else {
      return false
    }
  },
  lower (value, unit, inletValue, inletUnit) {
    if (isNaN(parseFloat(value)) === true || isNaN(parseFloat(inletValue)) === true) {
      return false
    }
    if (unit !== inletUnit) {
      inletValue = temperature.convert(inletValue, inletUnit, unit)
    }
    return value < inletValue
  },
  getUnits(units) {
    if (units in temperature.units){
      return temperature.units[units]
    } else {
      return temperature.getUnits(units=temperature.units.fallback)
    }
  }
}

export default temperature