// import user from './user'
// import token from './token'
import projectRequests from './project'
import projectsRequests from './projects'


const sonic = {
    project: projectRequests,
    projects: projectsRequests
}

export default sonic