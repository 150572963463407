<template>
  <div id="home">
    <b-jumbotron
      class="text-center mb-0 rounded-0"
      :style="{'background': 'url(' + require('@/assets/slideOne.jpg') + ') center center / cover no-repeat'}"
    >
      <h1 class="display-1 mt-6">{{$t('home.header')}}</h1>
      <h3 class="h3">{{$t('home.subHeader')}}</h3>
      <p class="h5 mb-6">{{$t('home.subSubHeader')}}</p>
    </b-jumbotron>
    <b-container class="mt-3">
      <b-card no-body class="overflow-hidden mb-3" @click="goTo('sonic.view')">
        <b-row no-gutters>
          <b-col md="2">
            <b-card-img-lazy src="https://picsum.photos/200/200/?image=58" class="rounded-0"></b-card-img-lazy>
          </b-col>
          <b-col md="10">
            <b-card-body>
              <b-card-text>
                <b-link class="h1 text-dark">{{$t('sonic.title')}}</b-link>
                <p>{{$t('sonic.description')}}</p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="overflow-hidden mb-3">
        <b-row no-gutters>
          <b-col md="2">
            <b-card-img-lazy src="https://picsum.photos/200/200/?image=58" class="rounded-0"></b-card-img-lazy>
          </b-col>
          <b-col md="10">
            <b-card-body>
              <b-card-text>
                <b-link :to="{name: 'flow.load'}" class="h1 text-dark">{{$t('flow.title')}}</b-link>
                <p>{{$t('flow.description')}}</p>
                <p>
                  <b-link
                    :to="{name: 'flow.load', query: { output: 'pressure' }}"
                  >{{$t('units.pressure.title')}}</b-link> |
                  <b-link
                    :to="{name: 'flow.load', query: { output: 'Kv' }}"
                  >{{$t('units.flowCoefficient.title')}}</b-link> |
                  <b-link :to="{name: 'flow.load', query: { output: 'mdot' }}">{{$t('units.mdot.title')}}</b-link>
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="overflow-hidden mb-3">
        <b-row no-gutters>
          <b-col md="2">
            <b-card-img-lazy :src="require('@/assets/H-s diagram.gif')" class="rounded-0"></b-card-img-lazy>
          </b-col>
          <b-col md="10">
            <b-card-body>
              <b-card-text>
                <b-link :to="{name: 'loss.view'}" class="h1 text-dark">{{$t('loss.title')}}</b-link>
                <p>{{$t('loss.description')}}</p>
                <p>
                  <b-link
                    :to="{name: 'loss.view', query: { u: 'metric', fu: 'Kv', cur: 'EUR' }}"
                  >EU preset</b-link> |
                  <b-link
                    :to="{name: 'loss.view', query: { u: 'imperial', fu: 'Cv', cur: 'USD' }}"
                  >USA preset</b-link> |
                  <b-link :to="{name: 'loss.view', query: { u: 'metric', fu: 'Kv', cur: 'GBP' }}">UK preset</b-link>
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body class="overflow-hidden mb-3" @click="goTo('props')">
        <b-row no-gutters>
          <b-col md="2">
            <b-card-img-lazy src="https://picsum.photos/200/200/?image=58" class="rounded-0"></b-card-img-lazy>
          </b-col>
          <b-col md="10">
            <b-card-body>
              <b-card-text>
                <b-link class="h1 text-dark">{{$t('props.title')}}</b-link>
                <p>{{$t('props.description')}}</p>
                <p>
                  <b-link
                    :to="{name: 'props', query: { u: 'metric' }}"
                  >EU preset</b-link> |
                  <b-link
                    :to="{name: 'props', query: { u: 'imperial' }}"
                  >USA preset</b-link> |
                  <b-link :to="{name: 'props', query: { u: 'si' }}">SI preset</b-link>
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "home",
  methods: {
    goTo(name) {
      this.$router.push({ name: name });
    },
  },
  created() {
    if (localStorage.getItem("accessToken")) {
      this.$store.dispatch("authUser")
    }
  }
};
</script>

<style scoped>
.display-1 {
  font-size: 4.5em;
}
@media screen and (max-width: 350px){
   .display-1 {
     font-size : 2.5em;
     word-break: word-break;
   }
}
</style>