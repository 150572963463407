import pressure from './pressure'
import temperature from './temperature'
import mdot from './mdot'

const properties = {
    pressure: pressure,
    temperature: temperature,
    mdot: mdot,
    validate( Pi, Ti, massflow ) {
        return pressure.validate(Pi.value, Pi.unit) && temperature.validate(Ti.value, Ti.unit) && mdot.validate(massflow.value, massflow.unit)
    }
}
export default properties