import logic from "@/scripts";

const state = {
  input: {
    select: "p-t",
    Pi: {
      value: "",
      unit: "bar",
    },
    Ti: {
      value: "",
      unit: "degc",
    },
    Hi: {
      value: "",
      unit: "kj/kg",
    },
  },
  result: {},
  settings: {
    form: {
      units: "metric",
      fluids: "water",
      detail: "advanced",
    },
    stored: {
      units: "metric",
      fluids: "water",
      detail: "advanced",
    },
  },
  request: {
    running: false,
    type: "undefined",
    error: null,
  },
};

const getters = {
  getProps: (state) => state.input,
  getPropsResults: (state) => state.result,
  getPropsSettings: (state) => state.settings,
  getPropsRequestError: (state) => state.request.error,
};

const actions = {
  async runPropsCalculator({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('openPropsRequest', 'calculating')
      if (logic.input.props.calculateable(state.input.Pi,state.input.Ti,state.input.Hi) === true) {
        var data = {
          P: [state.input.Pi.value,state.input.Pi.unit],
          T: [state.input.Ti.value,state.input.Ti.unit],
          H: [state.input.Hi.value,state.input.Hi.unit]
        }
        logic.request.props.calculate(data)
        .then((response) => {
          commit("setPropsResult", response.data);
          commit("closePropsRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("setPropsErrorMessage", errors.response.data.message);
          commit("closePropsRequest");
          reject(errors);
        });
      } else {
        commit("setPropsResult", {})
        commit("closePropsRequest");
      }
    });
  },
  getPropsStoreSettings({ commit }) {
    const storedUnits = localStorage.getItem("settingsUnits");
    const storedFluids = localStorage.getItem("settingsFluids");
    const storedDetail = localStorage.getItem("settingsDetail");
    var units, presUnit, tempUnit, enthUnit, fluids, detail;
    if (storedUnits === "imperial") {
      units = "imperial"
      presUnit = "psi"
      tempUnit = "degf"
      enthUnit = "btu/lb"
    } else if (storedUnits === "si") {
      units = "si"
      presUnit = "mpa"
      tempUnit = "kelvin"
      enthUnit = "kj/kg"
    } else {
      units = "metric"
      presUnit = "bar"
      tempUnit = "degc"
      enthUnit = "kj/kg"
    }
    if (storedFluids === "water") {
      fluids = "water";
    } else {
      fluids = "water";
    }
    if (storedDetail === "advanced") {
      detail = "advanced";
    } else {
      detail = "basic";
    }
    commit("setPropsPiUnit", presUnit);
    commit("setPropsTiUnit", tempUnit);
    commit("setPropsHiUnit", enthUnit);
    commit("setPropsSettingsUnits", units);
    commit("setPropsSettingsFluids", fluids);
    commit("setPropsSettingsDetail", detail);
    commit("setPropsSettingsStoredUnits", units);
    commit("setPropsSettingsStoredFluids", fluids);
    commit("setPropsSettingsStoredDetail", detail);
  },
  storePropsStoreSettings({ state, commit, dispatch }) {
    if (state.settings.form.units !== localStorage.getItem("settingsUnits")) {
      localStorage.setItem("settingsUnits", state.settings.form.units);
      dispatch("setGlobalUnits", state.settings.form.units)
    }
    if (state.settings.form.fluids !== localStorage.getItem("settingsFluids")) {
      localStorage.setItem("settingsFluids", state.settings.form.fluids);
      commit("setPropsSettingsStoredFluids", state.settings.form.fluids);
    }
    if (state.settings.form.detail !== localStorage.getItem("settingsDetail")) {
      localStorage.setItem("settingsDetail", state.settings.form.detail);
      commit("setPropsSettingsStoredDetail", state.settings.form.detail);
    }
  },
  setGlobalUnits({commit, dispatch}, units) {
    commit("setPropsSettingsStoredUnits", units);
    localStorage.setItem("settingsUnits", state.settings.form.units);
    dispatch("convertPressure", units);
    dispatch("convertTemperature", units);
    dispatch("convertEnthalpy", units);
    if (units === "imperial") {
      commit("setPropsPiUnit", "psi");
      commit("setPropsTiUnit", "degf");
      commit("setPropsHiUnit", "btu/lb");
    } else if (units === "si") {
      commit("setPropsPiUnit", "mpa");
      commit("setPropsTiUnit", "kelvin");
      commit("setPropsHiUnit", "kj/kg");
    } else {
      commit("setPropsPiUnit", "bar");
      commit("setPropsTiUnit", "degc");
      commit("setPropsHiUnit", "kj/kg");
    }
  },
  setGlobalFluid({commit}, fluid) {
    commit("setPropsSettingsStoredFluids", fluid);
    localStorage.setItem("settingsFluids", fluid);
  },
  setGlobalDetail({commit}, detail) {
    commit("setPropsSettingsStoredDetail", detail);
    localStorage.setItem("settingsDetail", detail);
  },
  storePropsPi({ commit, dispatch }, value) {
    commit("setPropsPi", value);
    dispatch("runPropsCalculator");
  },
  storePropsTi({ commit, dispatch }, value) {
    commit("setPropsTi", value);
    dispatch("runPropsCalculator");
  },
  storePropsHi({ commit, dispatch }, value) {
    commit("setPropsHi", value);
    dispatch("runPropsCalculator");
  },
  storePropsPiUnit({ commit, dispatch }, value) {
    dispatch("convertPressure", value);
    commit("setPropsPiUnit", value);
    dispatch("runPropsCalculator");
  },
  storePropsTiUnit({ commit, dispatch }, value) {
    dispatch("convertTemperature", value);
    commit("setPropsTiUnit", value);
    dispatch("runPropsCalculator");
  },
  storePropsHiUnit({ commit, dispatch }, value) {
    dispatch("convertEnthalpy", value);
    commit("setPropsHiUnit", value);
    dispatch("runPropsCalculator");
  },
  convertPressure({ state, commit }, newUnit) {
    const pressure = state.input.Pi.value;
    const prevUnit = state.input.Pi.unit;
    commit("setPropsPi", logic.input.pressure.convert(pressure,prevUnit,newUnit));
  },
  convertTemperature({ state, commit }, newUnit) {
    const temperature = state.input.Ti.value;
    const prevUnit = state.input.Ti.unit;
    commit("setPropsTi", logic.input.temperature.convert(temperature,prevUnit,newUnit));
  },
  convertEnthalpy({ state, commit }, newUnit) {
    const enthalpy = state.input.Hi.value;
    const prevUnit = state.input.Hi.unit;
    commit("setPropsHi", logic.input.enthalpy.convert(enthalpy,prevUnit,newUnit));
  },
};

const mutations = {
  openPropsRequest: (state, type) =>
    (state.request = { running: true, type: type, error: null }),
  closePropsRequest: (state) =>
    (state.request = { running: false, type: "undefined", error: null }),
  setPropsErrorMessage: (state, message) => (state.request.error = message),
  setPropsSelect: (state, value) => (state.input.select = value),
  setPropsPi: (state, value) => (state.input.Pi.value = value),
  setPropsPiUnit: (state, value) => (state.input.Pi.unit = value),
  setPropsTi: (state, value) => (state.input.Ti.value = value),
  setPropsTiUnit: (state, value) => (state.input.Ti.unit = value),
  setPropsHi: (state, value) => (state.input.Hi.value = value),
  setPropsHiUnit: (state, value) => (state.input.Hi.unit = value),
  setPropsResult: (state, value) => (state.result = value),
  setPropsSettingsUnits: (state, value) => (state.settings.form.units = value),
  setPropsSettingsFluids: (state, value) =>
    (state.settings.form.fluids = value),
  setPropsSettingsDetail: (state, value) =>
    (state.settings.form.detail = value),
  setPropsSettingsStoredUnits: (state, value) =>
    (state.settings.stored.units = value),
  setPropsSettingsStoredFluids: (state, value) =>
    (state.settings.stored.fluids = value),
  setPropsSettingsStoredDetail: (state, value) =>
    (state.settings.stored.detail = value),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
