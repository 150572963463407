import logic from "@/scripts";
import router from "../../router";

const state = {
  user: {
    accessToken: localStorage.getItem("accessToken") || null,
    refreshToken: localStorage.getItem("refreshToken") || null
  },
  client: {
    token: localStorage.getItem("clientToken") || null,
  },
  form: {
    login: {
      email: "",
      password: "",
    },
    register: {
      username: "",
      email: "",
      company: "",
      password: "",
      passwordRepeat: "",
    },
    profile: {
      username: "",
      email: "",
      company: ""
    },
    activate: {
      resend: {
        email: ""
      }
    },
    password: {
      send: {
        email: ""
      },
      reset: {
        password: "",
        passwordRepeat: "",
      }
    }
  },
  request: {
    running: false,
    action: null,
    message: null,
  },
  message: {
    login: "",
    activated: false,
    activationResend: null,
    passwordSend: null,
    passwordReset: null
  }
};

const getters = {
  isUserAuthenticated: (state) => !(state.user.accessToken === null),
  isClientAuthenticated: (state) => !(state.client.token === null),
  isUserLoading: (state) => state.request.running,
  getRegisterForm: (state) => state.form.register,
  getLoginForm: (state) => state.form.login,
  getProfileForm: (state) => state.form.profile,
  getActivateResendForm: (state) => state.form.activate.resend,
  getPasswordSendForm: (state) => state.form.password.send,
  getPasswordResetForm: (state) => state.form.password.reset,
  getLoginMessage: (state) => state.message.login,
  getActivatedStatus: (state) => state.message.activated,
  getActivationResend: (state) => state.message.activationResend,
  getPasswordSend: (state) => state.message.passwordSend,
  getPasswordReset: (state) => state.message.passwordReset
};

const actions = {
  async clientRegister({ commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "clientRegister");
      logic.request.auth.clientToken
        .register()
        .then((response) => {
          commit("setClientToken", response.data.device_token);
          commit("closeAuthRequest", "Registration succesfull");
          resolve(response);
        })
        .catch((error) => {
          commit("removeClientToken");
          commit("closeAuthRequest", "Client registration failed");
          reject(error);
        });
    });
  },
  async register({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "register");
      const data = {
        username: state.form.register.username,
        email: String(state.form.register.email).toLowerCase(),
        company: state.form.register.company,
        password: state.form.register.password
      };
      logic.request.auth.user
        .register(data)
        .then((response) => {
          commit("closeAuthRequest", "Registration succesfull");
          router.push({
            name: "activate.register",
          });
          resolve(response)
        })
        .catch((error) => {
          commit("removeUserToken");
          commit("closeAuthRequest", "Registration failed");
          reject(error)
        });
    })
  },
  async login({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "login");
      const data = {
        email: state.form.login.email,
        password: state.form.login.password
      };
      logic.request.auth.user
        .login(data, data)
        .then((response) => {
          commit("setAccessToken", response.data.access);
          commit("setRefreshToken", response.data.refresh);
          commit("setLoginFormEmail", "")
          commit("setLoginFormPassword", "")
          commit('setLoginMessage', "");
          router.push({
            name: "home",
          });
          commit("closeAuthRequest", "Login succesfull");
          resolve(response)
        })
        .catch((error) => {
          commit('setLoginMessage', "Login failed");
          commit("closeAuthRequest", "Login failed");
          reject(error)
        });
    })
  },
  async logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "logout");
      const data = {
        refresh_token: localStorage.getItem("refreshToken")
      }
      logic.request.auth.user.logout(data)
        .then((response) => {
          commit("removeAccessToken")
          commit("removeRefreshToken")
          router.push({
            name: "login",
          });
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  async fetchUserData({ commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "fetchUserData");
      logic.request.auth.user
      .fetchUserData()
      .then((response) => {
        const data = response.data[0]
        commit("setUserProfileUsername", data.username)
        commit("setUserProfileEmail", data.email)
        commit("setUserProfileCompany", data.company)
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  async activateAccount({ commit }, { uid, token }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "Activate account");
      const data = {
        uid: uid,
        token: token
      }
      logic.request.auth.user
        .activateAccount(data)
        .then((repsonse) => {
          commit('setActivatedAccount', true);
          router.push({
            name: "activate.activated",
          });
          commit("closeAuthRequest", "Activation succesfull");
          resolve(repsonse)
        })
        .catch((error) => {
          commit('setActivatedAccount', false);
          commit("closeAuthRequest", "Activation failed");
          reject(error)
        });
    })
  },
  async activationCodeResend({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "Activate account");
      const data = {
        email: state.form.activate.resend.email
      }
      logic.request.auth.user
        .activationCodeResend(data)
        .then((repsonse) => {
          commit('setActivationResend', true);
          commit("setActivateResendFormEmail", "")
          commit("closeAuthRequest", "Activation succesfull");
          resolve(repsonse)
        })
        .catch((error) => {
          commit('setActivationResend', false);
          commit("closeAuthRequest", "Activation failed");
          reject(error)
        });
    })
  },
  async sendPasswordReset({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "Activate account");
      const data = {
        email: state.form.password.send.email
      }
      logic.request.auth.user
        .sendPasswordReset(data)
        .then((repsonse) => {
          commit('setPasswordSend', true);
          commit("closeAuthRequest", "Activation succesfull");
          resolve(repsonse)
        })
        .catch((error) => {
          commit('setPasswordSend', false);
          commit("closeAuthRequest", "Activation failed");
          reject(error)
        });
    })
  },
  async resetPassword({ state, commit }, { uid, token }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "Activate account");
      const data = {
        uid: uid,
        token: token,
        new_password: state.form.password.reset.password
      }
      logic.request.auth.user
        .resetPassword(data)
        .then((repsonse) => {
          commit('setPasswordReset', true);
          commit("closeAuthRequest", "Activation succesfull");
          resolve(repsonse)
        })
        .catch((error) => {
          commit('setPasswordReset', false);
          commit("closeAuthRequest", "Activation failed");
          reject(error)
        });
    })
  },
  async authInitialize({ commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "authenticate");
      const userToken = localStorage.getItem("accessToken") || null;
      const clientToken = localStorage.getItem("clientToken") || null;
      logic.request.auth.user
        .validate(userToken)
        .then((response) => {
          commit("setAccessToken", response.data.access);
          commit("setRefreshToken", response.data.refresh);
          commit("closeAuthRequest", "Authenticate succesfull");
          resolve(response);
        })
        .catch(() => {
          commit("removeAccessToken");
          commit("removeRefreshToken");
          logic.request.auth.clientToken
            .validate(clientToken)
            .then((response) => {
              commit("setClientToken", response.data.key);
              commit("closeAuthRequest", "Authenticate succesfull");
              resolve(response);
            })
            .catch(() => {
              commit("removeClientToken");
              logic.request.auth.clientToken
                .get()
                .then((response) => {
                  commit("setClientToken", response.data.key),
                    resolve(response)
                })
                .catch((error) => {
                  commit("closeAuthRequest", "Authication failed");
                  reject(error);
                });
            });
        });
    });
  },
  async authUser({ commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "Authenticate user");
      const accessToken = localStorage.getItem("accessToken") || null;
      if (accessToken !== null) {
        // commit("setAccessToken", accessToken);
        logic.request.auth.user
          .validate(accessToken)
          .then((response) => {
            // commit("setAccessToken", response.data.access);
            // commit("setRefreshToken", response.data.refresh);
            commit("closeAuthRequest", "Authenticate succesfull");
            resolve(response);
          })
          .catch((error) => {
            commit("removeAccessToken");
            commit("removeRefreshToken");
            commit("closeAuthRequest", "Authenticate failed");
            reject(error);
          });
      } else {
        commit("closeAuthRequest", "Authenticate failed");
        reject("No user token found");
      }
    });
  },
  authClient({ commit }) {
    return new Promise((resolve, reject) => {
      commit("openAuthRequest", "Authenticate client");
      const clientToken = localStorage.getItem("clientToken") || null;
      if (clientToken !== null) {
        commit("setClientToken", clientToken);
        logic.request.auth.clientToken
          .validate(clientToken)
          .then((response) => {
            commit("closeAuthRequest", "Authenticate succesfull");
            resolve(response);
          })
          .catch((error) => {
            commit("removeClientToken");
            commit("closeAuthRequest", "Authenticate failed");
            reject(error);
          });
      } else {
        commit("closeAuthRequest", "Authenticate failed");
        reject("No device token found");
      }
    });
  },
};

const mutations = {
  openAuthRequest: (state, value) => {
    state.request.running = true;
    state.request.action = value;
    state.request.message = null;
  },
  closeAuthRequest: (state, value = null) => {
    state.request.running = false;
    state.request.action = null;
    state.request.message = value;
  },
  setLoginMessage: (state, message) => {
    state.message.login = message
  },
  setActivatedAccount: (state, status) => {
    state.message.activated = status
  },
  setActivationResend: (state, status) => {
    state.message.activationResend = status
  },
  setPasswordSend: (state, status) => {
    state.message.passwordSend = status
  },
  setPasswordReset: (state, status) => {
    state.message.passwordReset = status
  },
  setAccessToken: (state, token) => {
    localStorage.setItem("accessToken", token);
    logic.request.session.defaults.headers['Authorization'] = `Bearer ${token}`;
    state.user.accessToken = token;
  },
  removeAccessToken: (state) => {
    localStorage.removeItem("accessToken");
    delete logic.request.session.defaults.headers.Authorization;
    state.user.accessToken = null;
  },
  setRefreshToken: (state, token) => {
    localStorage.setItem("refreshToken", token);
    state.user.refreshToken = token;
  },
  removeRefreshToken: (state) => {
    localStorage.removeItem("refreshToken");
    state.user.refreshToken = null;
  },
  setClientToken: (state, token) => {
    localStorage.setItem("clientToken", token);
    state.client.token = token;
  },
  removeClientToken: (state) => {
    localStorage.removeItem("clientToken");
    state.client.token = null;
  },
  setRegisterFormUsername: (state, username) => {
    state.form.register.username = username
  },
  setRegisterFormEmail: (state, email) => {
    state.form.register.email = email
  },
  setRegisterFormCompany: (state, company) => {
    state.form.register.company = company
  },
  setRegisterFormPassword: (state, password) => {
    state.form.register.password = password
  },
  setRegisterFormPasswordRepeat: (state, passwordRepeat) => {
    state.form.register.passwordRepeat = passwordRepeat
  },
  setLoginFormEmail: (state, email) => {
    state.form.login.email = email
  },
  setLoginFormPassword: (state, password) => {
    state.form.login.password = password
  },
  setUserProfileUsername: (state, username) => {
    state.form.profile.username = username
  },
  setUserProfileEmail: (state, email) => {
    state.form.profile.email = email
  },
  setUserProfileCompany: (state, company) => {
    state.form.profile.company = company
  },
  setActivateResendFormEmail: (state, email) => {
    state.form.activate.resend.email = email
  },
  setPasswordSendFormEmail: (state, email) => {
    state.form.password.send.email = email
  },
  setPasswordResetFormPassword: (state, password) => {
    state.form.password.reset.password = password
  },
  setPasswordResetFormPasswordRepeat: (state, passwordRepeat) => {
    state.form.password.reset.passwordRepeat = passwordRepeat
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
