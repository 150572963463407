import pressure from './pressure'
import temperature from './temperature'
import mdot from './mdot'
import flowCoefficient from './flowCoefficient'
import sizing from './sizing'

const properties = {
    pressure: pressure,
    temperature: temperature,
    mdot: mdot,
    flowCoefficient: flowCoefficient,
    sizing: sizing,
    validate(Pi, Ti, massflow, flowValue) {
        return pressure.validate(Pi.value, Pi.unit) && temperature.validate(Ti.value, Ti.unit) && mdot.validate(massflow.value, massflow.unit) && flowCoefficient.validate(flowValue.value, flowValue.unit)
    },
    validatePressure(Pi, Ti, massflow, flowValue) {
        return pressure.validate(Pi.value, Pi.unit) && temperature.validate(Ti.value, Ti.unit) && mdot.validate(massflow.value, massflow.unit) && flowCoefficient.validate(flowValue.value, flowValue.unit)
    },
    validateFlowCoefficient(Pi, Ti, Pe, massflow) {
        return pressure.validate(Pi.value, Pi.unit) && temperature.validate(Ti.value, Ti.unit) && pressure.validate(Pe.value, Pe.unit) && mdot.validate(massflow.value, massflow.unit)
    },
    validateMassFlow(Pi, Ti, Pe, flowValue) {
        return pressure.validate(Pi.value, Pi.unit) && temperature.validate(Ti.value, Ti.unit) && pressure.validate(Pe.value, Pe.unit) && flowCoefficient.validate(flowValue.value, flowValue.unit)
    },
    validateSizing(inletSizing, outletSizing) {
        return sizing.validate(inletSizing) && sizing.validate(outletSizing)
    }
}
export default properties