import length from './length'
import din from './din'
import ansi from './ansi'
import inletDiameter from './partDiameter'

const pipe = {
  validate (part) {
    if (length.validate(part.length, part.lengthUnit) === true) {
      if (part.inletSizing === "DIN") {
        return din.validate(part.inletSizing)
      } else if (part.inletSizing === "ANSI") {
        return ansi.validate(part.inletSizing)
      } else if (part.inletSizing === "COSTUM") {
        return inletDiameter.validate(part.inletSize, part.inletSizeUnit)
      }
    }
    return false  
  }
}

export default pipe