import axios from 'axios';
import router from '@/router'

// const CSRF_COOKIE_NAME = 'csrftoken';
// const CSRF_HEADER_NAME = 'X-CSRFToken';

const baseURL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: localStorage.getItem('accessToken') ?
            `Bearer ${localStorage.getItem('accessToken')}` : null,
        // 'Content-Type': 'application/json',
        accept: 'application/json'
    }
});

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async function (error) {
		const originalRequest = error.config;

		if (typeof error.response === 'undefined') {
			alert(
				'A server/network error occurred. ' +
					'Looks like CORS might be the problem. ' +
					'Sorry about this - we will get it fixed shortly.'
			);
			return Promise.reject(error);
		}

		if (
			error.response.status === 401 &&
			originalRequest.url === baseURL + '/auth/jwt/refresh/'
		) {
			router.push({
				name: "login",
			});
			return Promise.reject(error);
		}

		if (
			error.response.data.code === 'token_not_valid' &&
			error.response.status === 401
		) {
			const refreshToken = localStorage.getItem('refreshToken');

			if (refreshToken) {
				const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

				// exp date in token is expressed in seconds, while now() returns milliseconds:
				const now = Math.ceil(Date.now() / 1000);
				// console.log(tokenParts.exp);
				if (tokenParts.exp > now) {
					delete axiosInstance.defaults.headers.Authorization;
					return axiosInstance
						.post('/auth/jwt/refresh/', { refresh: refreshToken })
						.then((response) => {
							const accessToken = response.data.access;
							const refreshToken = response.data.refresh;
							// console.log(accessToken)
							// console.log(refreshToken)
							localStorage.setItem('accessToken', accessToken);
							localStorage.setItem('refreshToken', refreshToken);
							if (originalRequest.url === `${baseURL}/auth/jwt/verify/`) {
								originalRequest.data = {
									'token': accessToken
								}
							}
							
							axiosInstance.defaults.headers['Authorization'] =
								'Bearer ' + accessToken;
							originalRequest.headers['Authorization'] =
								'Bearer ' + accessToken;
							return axiosInstance(originalRequest);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					console.log('Refresh token is expired', tokenParts.exp, now);
					router.push({
						name: "login",
					});
				}
			} else {
				console.log('Refresh token not available.');
				router.push({
					name: "login",
				});
			}
		}
		// specific error handling done elsewhere
		return Promise.reject(error);
	}
);

export default axiosInstance;