const din = {
  validate(sizing) {
    if (sizing.standard === "ANSI") {
      if (sizing.size) {
        if (sizing.norm) {
          return true
        }
      }
    }
    return false
  }
}

export default din