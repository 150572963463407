<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" class="text-center">
    <b-link :to="{name: 'home'}" class="d-flex align-items-center">
      <img :src="require('@/assets/redicon.png')" :width="38" :height="38" alt="logo" class="mr-2">
      <b-navbar-brand>
        {{$t('general.title')}}
      </b-navbar-brand>
    </b-link>
    

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-link :to="{name: 'home'}" router-tag="b-nav-item">
          {{$t('home.navTitle')}}
        </b-link>
        <b-link :to="{ name: 'sonic.view' }" router-tag="b-nav-item">
          {{$t('sonic.navTitle')}}
        </b-link>
        <b-link :to="{ name: 'flow.load' }" v-show="true" router-tag="b-nav-item">
          {{$t('flow.navTitle')}}
        </b-link>
        <b-link :to="{ name: 'loss.view' }" v-show="true" router-tag="b-nav-item">
          {{$t('loss.navTitle')}}
        </b-link>
        <b-link :to="{ name: 'props' }" v-show="true" router-tag="b-nav-item">
          {{$t('props.navTitle')}}
        </b-link>
        <b-link :to="{ name: 'feedback' }" router-tag="b-nav-item">
          {{$t('feedback.navTitle')}}
        </b-link>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <lang />
        <userbutton v-show="true" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import userbutton from '../nav/userbutton.vue'
import lang from '../nav/lang.vue'
export default {
  data() {
    return {}
  },
  components: {
    userbutton,
    lang
  }
}
</script>

<style scoped>
</style>