<template>
  <div v-if="isUserLoading !== 'loading'">
    <b-nav-item-dropdown v-if="isUserAuthenticated" size="lg"  variant="link" toggle-class="text-decoration-none" no-caret right>
        <!-- Using 'button-content' slot -->
        <template v-slot:button-content>
            <i class="fas fa-user"></i>
        </template>
        <b-link :to="{ name: 'user.profile' }" router-tag="b-dropdown-item">
            {{$t('user.profile')}}
        </b-link>
        <b-link :to="{ name: 'logout' }" router-tag="b-dropdown-item">
            {{$t('user.logout')}}
        </b-link>
        </b-nav-item-dropdown>
        <div v-if="!isUserAuthenticated">
        <b-link :to="{ name: 'login' }" :disabled="false" class="mr-1">
            <b-button variant="success"><i class="fas fa-user"></i> {{$t('user.login')}}</b-button>
        </b-link>
        <b-link :to="{ name: 'register' }" :disabled="false">
            <b-button variant="info"><i class="fas fa-user-plus"></i> {{$t('user.register')}}</b-button>
        </b-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'userButton',
    computed: mapGetters({
        isUserLoading: 'isUserLoading',
        isUserAuthenticated: 'isUserAuthenticated'
    })
}
</script>

<style>

</style>