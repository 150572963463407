const projectName = {
    min: 3,
    max: 64,
    minLength(name) {
      name = String(name)
      return name.length >= projectName.min
    },
    maxLength(name) {
      name = String(name)
      return name.length <= projectName.max
    },
    validate(name) {
      return projectName.minLength(name) && projectName.maxLength(name);
    },
  };

export default projectName