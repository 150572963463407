const password = {
    min: 8,
    max: 64,
    minChar: 1,
    minCap: 1,
    minNum: 1,
    minSpec: 1,
    minLength(input) {
        input = String(input)
        return input.length >= password.min
    },
    maxLength(input) {
      input = String(input)
      return input.length <= password.max
    },
    minCharacter(input) {
        var charFormat = /^(?=.*[a-z]).+$/;
        return charFormat.test(input)
    },
    minCapital(input) {
        var capFormat = /^(?=.*[A-Z]).+$/;
        return capFormat.test(input)
    },
    minNumber(input) {
        var numFormat = /^(?=.*[0-9]).+$/;
        return numFormat.test(input)
    },
    minSpecial(input) {
        var specFormat = /^(?=.*[!@#$%^&*]).+$/;
        return specFormat.test(input)
    },
    validate(input) {
        return password.minLength(input) && password.maxLength(input) && password.minCharacter(input) && password.minCapital(input) && password.minNumber(input) && password.minSpecial(input)
    }
}

export default password