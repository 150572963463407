<template>
  <div id="app">
    <Navigation />
    <router-view/>
  </div>
</template>

<script>
import Navigation from '../src/components/nav/nagivation.vue'
export default {
  name: 'App',
  components: {
    Navigation
  },
}
</script>

<style>

</style>
