import Diameter from './diameter'
import pipe from './pipe'
import valve from './valve'
import elbow from './elbow'
import flowCoefficient from './flowCoefficient'
import Name from './name'
import Type from './type'
import Length from './length'

export default {
    diameter: Diameter,
    pipe: pipe,
    valve: valve,
    elbow: elbow,
    partType: Type,
    name: Name,
    length: Length,
    flowCoefficient: flowCoefficient,
    validate(part) {
        if (Type.validate(part.type) === true) {
          if (Name.validate(part.name) === true) {
            if (part.type === "pipe") {
              return pipe.validate(part)
            } else if (part.type === "valve") {
              return valve.validate(part)
            } else if (part.type === "elbow") {
              return elbow.validate(part)
            }
          }
        }
        return false
      }
}