import pressure from './global/pressure'
import temperature from './global/temperature'
import enthalpy from './global/enthalpy'
import mdot from './global/mdot'
import sonic from './sonic'
import loss from './loss'
import flow from './flow'
import props from './props'
import user from './user'

const input = {
  pressure: pressure,
  temperature: temperature,
  enthalpy: enthalpy,
  mdot: mdot,
  sonic: sonic,
  loss: loss,
  flow: flow,
  props: props,
  user: user
}

export default input