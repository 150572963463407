const email = {
    emailFormat(input) {
        if (!input) {
            return false
        }
        var mailformat = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
        return (mailformat.test(String(input).toLowerCase()))
    },
    validate(input) {
        return email.emailFormat(input)
    },
}

export default email