export default {
    validate(piping) {
        var valid = true
        if (piping.length === 0) {
            return false
        }
        piping.forEach((part, index) => {
            if (index > 0) {
                if (part.inletSizing !== piping[index - 1].outletSizing) {
                    valid = false
                }
                if (part.standardInletNorm !== piping[index - 1].standardOutletNorm) {
                    valid =  false
                }
                if (part.standardInletSize !== piping[index - 1].standardOutletSize) {
                    valid =  false
                }
                if (part.costumInletSize !== piping[index - 1].costumOutletSize) {
                    valid =  false
                }
                if (part.costumInletSizeUnit !== piping[index - 1].costumOutletSizeUnit) {
                    valid =  false
                }
            }
        });
        return valid
    }
}