import properties from './properties'
import parts from './parts'

const loss = {
    parts: parts,
    properties: properties,
    calculateable(items,Pi,Ti,massFlow) {
        return parts.validate(items) && properties.validate(Pi,Ti,massFlow)
    }
}

export default loss