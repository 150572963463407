import passwordForm from './password'
import passwordRepeatForm from './passwordRepeat'

const reset = {
    password: passwordForm,
    passwordRepeat: passwordRepeatForm,
    validate(input1, input2) {
        return passwordForm.validate(input1) && passwordRepeatForm.validate(input1, input2)
    }
}

export default reset