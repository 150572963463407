import store from '../store'

const projectSonic = {
    async initialize() {
        return await new Promise((resolve, reject) => {
            store.dispatch('getSonicProjects')
                .then(response => {
                    resolve(response)
                })
                .catch(errors => {
                    reject(errors)
                })
        })
    },
    // async shareLink(id) {
    //     return await new Promise((resolve, reject) => {
    //         store.dispatch('sharedSonicProject')
    //             .then(response => {
    //                 resolve(response)
    //             })
    //             .catch(errors => {
    //                 reject(errors)
    //             })
    //     })
    // },
    async getProject(id) {
        return await new Promise((resolve, reject) => {
            if (store.getters.getSonicProjectsCount > 0) {
                const projects = store.getters.getSonicProjects
                if (projects.filter(project => project.project_id === id)) {
                    store.dispatch('setSonicProject', id)
                        .then(response => {
                            resolve(response)
                        })
                        .catch(() => {
                            store.dispatch('getSonicProject', id)
                                .then(response => {
                                    resolve(response)
                                })
                                .catch(errors => {
                                    reject(errors)
                                })
                        })
                }
            } else {
                store.dispatch('getSonicProject', id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            }
        })
    },
    async getResults(id) {
        return await new Promise((resolve, reject) => {
            if (store.getters.getSonicResultState === false) {
                store.dispatch('getSonicResults', id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
            } else {
                resolve({ message: "Information already calculated" })
            }
        })
    }
}

export default projectSonic