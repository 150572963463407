import properties from './properties'
import project from './project'
import parts from './parts'
import piping from './piping'
const sonic = {
    parts: parts,
    piping: piping,
    project: project,
    properties: properties
}

export default sonic