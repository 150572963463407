const DIN = [
    {
        DIN: 10,
        PN: {
            40: [17.2, 2.0],
            63: [17.2, 2.0],
            100: [17.2, 2.0],
            160: [17.2, 2.0],
            250: [17.2, 2.6],
            320: [17.2, 2.6],
            400: [17.2, 3.6],
            630: [21.3, 5.0],
        }
    },
    {
        DIN: 15,
        PN: {
            40: [21.3, 2.0],
            63: [21.3, 2.0],
            100: [21.3, 2.0],
            160: [21.3, 2.0],
            250: [21.3, 2.6],
            320: [21.3, 3.2],
            400: [26.9, 5.0],
            630: [33.7, 8.0],
        }
    },
    {
        DIN: 25,
        PN: {
            40: [33.7, 2.6],
            63: [33.7, 2.6],
            100: [33.7, 2.6],
            160: [33.7, 3.2],
            250: [33.7, 3.6],
            320: [33.7, 5.0],
            400: [42.4, 7.1],
            630: [48.3, 12.5],
        }
    },
    {
        DIN: 40,
        PN: {
            40: [48.3, 2.6],
            63: [48.3, 2.6],
            100: [48.3, 2.6],
            160: [48.3, 3.6],
            250: [48.3, 5.0],
            320: [48.3, 6.3],
            400: [60.3, 11.0],
            630: [76.1, 17.5],
        },
    },
    {
        DIN: 50,
        PN: {
            40: [60.3, 3.2],
            63: [60.3, 3.2],
            100: [60.3, 3.2],
            160: [60.3, 4.0],
            250: [60.3, 8.0],
            320: [76.1, 8.8],
            400: [76.1, 14.2],
            630: [88.9, 20.0],
        },
    },
    {
        DIN: 65,
        PN: {
            40: [76.1, 3.6],
            63: [76.1, 3.6],
            100: [76.1, 3.6],
            160: [76.1, 5.6],
            250: [76.1, 8.8],
            320: [88.9, 11.0],
            400: [114.3, 17.5],
        },
    },
    {
        DIN: 80,
        PN: {
            40: [88.9, 4.0],
            63: [88.9, 4.0],
            100: [88.9, 4.0],
            160: [88.9, 6.3],
            250: [114.3, 11.0],
            320: [114.3, 14.2],
            400: [114.3, 17.5],
        },
    },
    {
        DIN: 100,
        PN: {
            40: [114.3, 5.0],
            63: [114.3, 5.0],
            100: [114.3, 5.0],
            160: [114.3, 8.0],
        },
    },
    {
        DIN: 125,
        PN: {
            40: [139.7, 4.5],
            63: [139.7, 4.5],
            100: [139.7, 6.3],
            160: [139.7, 10.0],
        },

    },
    {
        DIN: 150,
        PN: {
            40: [168.3, 5.6],
            63: [168.3, 5.6],
            100: [168.3, 7.1],
            160: [168.3, 12.5],
        },
    },
    {
        DIN: 200,
        PN: {
            40: [219.1, 7.1],
            63: [219.1, 7.1],
            100: [219.1, 10.0],
            160: [219.1, 16.0],
        },
    },
    {
        DIN: 250,
        PN: {
            40: [273.0, 8.0],
            63: [273.0, 8.8],
            100: [273.0, 12.5],
        },
    },
    {
        DIN: 300,
        PN: {
            40: [323.9, 8.0],
            63: [323.9, 11.0],
            100: [323.9, 14.2],
        },
    },
    {
        DIN: 350,
        PN: {
            40: [355.6, 8.8],
            63: [355.6, 12.5],
            100: [355.6, 16.0],
        },
    },
    {
        DIN: 400,
        PN: {
            40: [406.4, 11.0],
            63: [406.4, 14.2],
        },
    },
    {
        DIN: 500,
        PN: {
            40: [508.0, 14.2],
        }
    },
    {
        DIN: 600,
        PN: {
            40: [610.0, 14.2],
        }
    },
    {
        DIN: 700,
        PN: {
            40: [711.0, 14.2],
        }
    },
    {
        DIN: 800,
        PN: {
            40: [813.0, 14.2],
        }
    },
    {
        DIN: 900,
        PN: {
            40: [914.0, 14.2],
        }
    }
]

export default DIN