import usernameForm from './username'
import emailForm from './email'
import companyForm from './company'
import passwordForm from './password'
import passwordRepeatForm from './passwordRepeat'

const register = {
    username: usernameForm,
    email: emailForm,
    company: companyForm,
    password: passwordForm,
    passwordRepeat: passwordRepeatForm,
    validate(input1, input2, input3, input4, input5) {
        return usernameForm.validate(input1) && emailForm.validate(input2) && companyForm.validate(input3) && passwordForm.validate(input4) && passwordRepeatForm.validate(input4, input5)
    }
}

export default register