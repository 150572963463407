import Vue from "vue";
import router from "../../router";
import logic from "@/scripts";
import ANSI from "./norm/ANSI.js";
import DIN from "./norm/DIN.js";

const state = {
  clientToken: localStorage.getItem("clientToken") || null,
  projects: [],
  project_id: "",
  project: {
    name: "",
    description: "",
    properties: "",
    piping: [],
    properties_complete: false,
    piping_complete: false,
    complete: {
      properties: false,
      piping: false,
    },
  },
  stored: {
    name: "",
    description: "",
    properties: "",
    piping: [],
  },
  resultProjectId: "",
  results: {},
  resultsProperties: {},
  resultState: false,
  share_link: "",
  selected_share: "access",
  makeProject: {
    name: "",
    description: "",
  },
  piping: [],
  form: {
    project: {
      name: "",
      description: "",
    },
    properties: {
      Pi: {
        value: "",
        unit: "bar",
      },
      Ti: {
        value: "",
        unit: "degc",
      },
      Hi: {
        value: "",
        unit: "kj/kg",
      },
      Pe: {
        value: "",
        unit: "bar",
      },
      Te: {
        value: "",
        unit: "degc",
      },
      He: {
        value: "",
        unit: "kj/kg",
      },
    },
    part: {
      type: null,
      name: "",
      length: "",
      lengthUnit: "m",
      flowValue: "",
      flowUnit: "",
      inletSizing: "DIN",
      outletSizing: "DIN",
      standardInletSize: 10,
      standardOutletSize: 10,
      standardInletNorm: "40",
      standardOutletNorm: "40",
      costumInletSize: "",
      costumInletSizeUnit: "mm",
      costumOutletSize: "",
      costumOutletSizeUnit: "mm",
      edit: false,
    },
    form: [],
    sizingListInlet: DIN,
    sizingListOutlet: DIN,
    normListInlet: DIN[0].PN,
    normListOutlet: DIN[0].PN,
  },
  request: {
    running: false,
    type: "undefined",
  },
  settings: {
    fluid: "water",
    units: "metric",
  },
};

const getters = {
  getSonicProjects: (state) => state.projects,
  getSonicProject: (state) => state.project,
  getSonicProjectsCount: (state) => state.projects.length,
  getSonicCurrentProjectId: (state) => state.project_id,

  getSonicStoredName: (state) => state.stored.name,
  getSonicStoredDescription: (state) => state.stored.description,
  getSonicStoredProperties: (state) => state.stored.properties,
  getSonicStoredPiping: (state) => state.stored.piping,

  getSonicProjectForm: (state) => state.form.project,
  getSonicPropertiesForm: (state) => state.form.properties,
  getSonicPartForm: (state) => state.form.part,
  getSonicPipingForm: (state) => state.form.piping,
  getSonicSizingListInlet: (state) => state.form.sizingListInlet,
  getSonicSizingListOutlet: (state) => state.form.sizingListOutlet,
  getSonicNormListInlet: (state) => state.form.normListInlet,
  getSonicNormListOutlet: (state) => state.form.normListOutlet,

  getSonicResults: (state) => state.results,
  getSonicResultsProperties: (state) => state.resultsProperties,
  getSonicResultState: (state) => state.resultState,

  getSonicPropertiesStored: (state) => {
    const stored = state.stored.properties
    const form = state.form.properties
    if (!stored) {
      return false
    }
    const props1 = {
      "Pi": {
        "value": String(stored.Pi[0]),
        "unit": stored.Pi[1]
      },
      "Ti": {
        "value": String(stored.Ti[0]),
        "unit": stored.Ti[1]
      },
      "Pe": {
        "value": String(stored.Pe[0]),
        "unit": stored.Pe[1]
      }
    }
    const props2 = {
      "Pi": {
        "value": String(form.Pi.value),
        "unit": form.Pi.unit
      },
      "Ti": {
        "value": String(form.Ti.value),
        "unit": form.Ti.unit
      },
      "Pe": {
        "value": String(form.Pe.value),
        "unit": form.Pe.unit
      }
    }
    return JSON.stringify(props1) === JSON.stringify(props2)
  },
  getSonicPipingStored: (state) => {
    const stored = state.stored.piping
    const form = state.form.piping
    return JSON.stringify(stored) === JSON.stringify(form)
  },
  isSonicProjectComplete: (state, getters) =>
    state.project.complete.properties === true &&
    state.project.complete.piping === true &&
    getters.getSonicPropertiesStored === true &&
    getters.getSonicPipingStored === true,
  isSonicProjectItemsComplete: (state) => state.project.complete,
  isSonicLoading: (state) => state.request.running,

  getSonicSettings: (state) => state.settings,
  isSonicProjectInProjects: (state) =>
    state.projects.filter((project) => project.project_id === state.project_id)
      .length > 0,

  isSonicCalculating: (state) => state.request.type === "calculating",
  isSonicCalculated: (state) => {
    return state.resultState
  }
};

const actions = {
  async getSonicProjects({ commit }) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "projects");
      logic.request.sonic.projects
        .getProjects()
        .then((response) => {
          commit("setSonicProjects", response.data);
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("closeSonicRequest");
          reject(errors);
        });
    });
  },
  async setSonicProject({ state, commit, dispatch }, projectId) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "setProject");
      if (!projectId) {
        commit("closeSonicRequest");
        reject("No project id provided");
      } else if (state.project_id === projectId) {
        commit("closeSonicRequest");
        resolve({ message: "Project already set" });
      } else {
        commit("setSonicProjectId", projectId);
        var data = state.projects.filter(
          (project) => project.project_id === projectId
        );
        data = data[0];
        // const properties = data.properties;
        // const piping = data.piping;
         
        commit("setSonicStoredName", data.name || "");
        commit("setSonicStoredDescription", data.description || "");
        commit("setSonicStoredProperties", data.properties || "");
        commit("setSonicStoredPiping", data.piping || []);
        commit("setSonicPropertiesComplete", data.properties_complete || false);
        commit("setSonicPipingComplete", data.piping_complete || false);
        dispatch("setSonicFormProperties", data.properties)
        dispatch("setSonicFormPiping", data.piping);
        if (state.resultProjectId !== projectId) {
          commit("setSonicResults", {});
          commit("setSonicResultState", false);
        }
        commit("closeSonicRequest");
        resolve("Project set");
      }
    });
  },
  async getSonicProject({ commit, dispatch }, projectId) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "getProject");
      logic.request.sonic.projects
        .getProject(projectId)
        .then((response) => {
          commit("setSonicProjectId", projectId);
          // commit('setSonicProject', response.data)
          const data = response.data;
          // const properties = data.properties;
          // const piping = data.piping;
          commit("setSonicStoredName", data.name || "");
          commit("setSonicStoredDescription", data.description || "");
          commit("setSonicStoredProperties", data.properties || "");
          commit("setSonicStoredPiping", data.piping || []);
          commit("setSonicPropertiesComplete", data.properties_complete || false);
          commit("setSonicPipingComplete", data.piping_complete || false);
          dispatch("setSonicFormProperties", data.properties)
          dispatch("setSonicFormPiping", data.piping);
          if (state.resultProjectId !== projectId) {
            commit("setSonicResults", {});
            commit("setSonicResultState", false);
          }
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("setSonicProjectId", "");
          commit("closeSonicRequest");
          reject(errors);
        });
    });
  },
  async createSonicProject({ state, commit, dispatch }) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "project");
      const data = {
        name: state.form.project.name,
        description: state.form.project.description,
      };
      logic.request.sonic.projects
        .createProject(data)
        .then((response) => {
          commit("appendSonicProject", response.data);
          commit("setSonicProjectId", response.data.project_id);
          // commit('setSonicProject', response.data)
          commit("setSonicStoredName", response.data.name || "");
          commit("setSonicStoredDescription", response.data.description || "");
          commit("setSonicProjectFormName", "");
          commit("setSonicProjectFormDescription", "");
          commit("setSonicStoredProperties", null);
          commit("setSonicStoredPiping", null);
          dispatch("setSonicFormProperties", response.data.properties)
          dispatch("setSonicFormPiping", response.data.piping);
          commit("setSonicPropertiesComplete", false);
          commit("setSonicPipingComplete", false);
          commit(
            "setSonicPropertiesFormPiUnit",
            logic.input.pressure.getUnits(state.settings.units)
          );
          commit(
            "setSonicPropertiesFormTiUnit",
            logic.input.temperature.getUnits(state.settings.units)
          );
          commit(
            "setSonicPropertiesFormPeUnit",
            logic.input.pressure.getUnits(state.settings.units)
          );
          commit("setSonicResultProjectId", "");
          commit("setSonicResults", {});
          commit("setSonicResultState", false);
          router.push({
            name: "sonic.input",
            params: { id: response.data.project_id },
          });
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("closeSonicRequest");
          reject(errors);
        });
    });
  },
  clearSonicProjectForm({ commit }) {
    commit("setSonicProjectFormName", "");
    commit("setSonicProjectFormDescription", "");
  },
  async runSonicCalculator({ state, commit }) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "calculating");
      logic.request.sonic.project
        .calculate(state.project_id)
        .then((response) => {
          const properties = response.data[0].properties;
          const results = response.data[0].results;
          commit("setSonicResultProjectId", state.project_id);
          commit("setSonicResultsProperties", properties);
          commit("setSonicResults", results);
          commit("setSonicResultState", true);
          router.push({
            name: "sonic.output",
            params: { id: state.project_id },
          });
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("setSonicResultProjectId", "");
          commit("setSonicResults", {});
          commit("setSonicResultState", false);
          commit("closeSonicRequest");
          reject(errors);
        });
    });
  },
  async getSonicResults({ commit, state }, projectId) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "results");
      if (state.resultProjectId === projectId) {
        commit("closeSonicRequest");
        resolve({ message: "Results already gathered" });
      } else {
        logic.request.sonic.project
          .results(projectId)
          .then((response) => {
            const properties = response.data.properties;
            const results = response.data.results;
            commit("setSonicResultProjectId", projectId);
            commit("setSonicResultsProperties", properties);
            commit("setSonicResults", results);
            commit("setSonicResultState", true);
            commit("closeSonicRequest");
            resolve(response);
          })
          .catch((errors) => {
            commit("setSonicResultProjectId", "");
            commit("setSonicResults", {});
            commit("setSonicResultState", false);
            commit("closeSonicRequest");
            reject(errors);
          });
      }
    });
  },
  async storeSonicProjectDetails({ commit, dispatch, state }, type) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", type);
      var data = {};
      if (type === "properties") {
        data["properties"] = {};
        data["properties"]["Pi"] = [
          state.form.properties.Pi.value,
          state.form.properties.Pi.unit,
        ];
        data["properties"]["Ti"] = [
          state.form.properties.Ti.value,
          state.form.properties.Ti.unit,
        ];
        data["properties"]["He"] = [
          state.form.properties.He.value,
          state.form.properties.He.unit,
        ];
        data["properties"]["Pe"] = [
          state.form.properties.Pe.value,
          state.form.properties.Pe.unit,
        ];
        data["properties"]["Te"] = [
          state.form.properties.Te.value,
          state.form.properties.Te.unit,
        ];
        data["properties"]["He"] = [
          state.form.properties.He.value,
          state.form.properties.He.unit,
        ];
        data["properties"] = JSON.stringify(data["properties"]);
      }
      if (type === "piping") {
        data["piping"] = JSON.stringify(state.form.piping);
      }
      logic.request.sonic.project
        .store(state.project_id, data)
        .then((response) => {
          data = response.data;
          // const properties = data.properties;
          // const piping = data.piping;
          commit("setSonicStoredProperties", data.properties || "");
          commit("setSonicStoredPiping", data.piping || []);
          commit(
            "setSonicPropertiesComplete",
            response.data.properties_complete || false
          );
          commit(
            "setSonicPipingComplete",
            response.data.piping_complete || false
          );
          dispatch("setSonicFormProperties", data.properties)
          dispatch("setSonicFormPiping", data.piping);
          commit("setSonicResultState", false);
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("closeSonicRequest");
          reject(errors);
        });
    });
  },
  setSonicFormProperties({ commit }, properties) {
    try {
      commit(
        "setSonicPropertiesFormPi",
        parseFloat(properties.Pi[0]) || ""
      );
      commit(
        "setSonicPropertiesFormTi",
        parseFloat(properties.Ti[0]) || ""
      );
      commit(
        "setSonicPropertiesFormPe",
        parseFloat(properties.Pe[0]) || ""
      );
      commit(
        "setSonicPropertiesFormPiUnit",
        properties.Pi[1] ||
        logic.input.pressure.getUnits(state.settings.units)
      );
      commit(
        "setSonicPropertiesFormTiUnit",
        properties.Ti[1] ||
        logic.input.temperature.getUnits(state.settings.units)
      );
      commit(
        "setSonicPropertiesFormPeUnit",
        properties.Pe[1] ||
        logic.input.pressure.getUnits(state.settings.units)
      );
    } catch (errors) {
      commit("setSonicPropertiesFormPi", "");
      commit("setSonicPropertiesFormTi", "");
      commit("setSonicPropertiesFormPe", "");
      commit(
        "setSonicPropertiesFormPiUnit",
        logic.input.pressure.getUnits()
      );
      commit(
        "setSonicPropertiesFormTiUnit",
        logic.input.temperature.getUnits()
      );
      commit(
        "setSonicPropertiesFormPeUnit",
        logic.input.pressure.getUnits()
      );
    }
  },
  setSonicFormPiping({ commit }, piping) {
    const form = []
    if (piping) {
      piping.forEach(part => {
        form.push(part)
      })
    }
    commit("setSonicPipingForm", form || piping || [])
  },
  storeSonicPropertiesPi({ commit }, value) {
    commit("setSonicPropertiesFormPi", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesTi({ commit }, value) {
    commit("setSonicPropertiesFormTi", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesPe({ commit }, value) {
    commit("setSonicPropertiesFormPe", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesHi({ commit }, value) {
    commit("setSonicPropertiesFormHi", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesPiUnit({ commit }, value) {
    let newValue = logic.input.pressure.convert(
      state.form.properties.Pi.value,
      state.form.properties.Pi.unit,
      value
    );
    commit("setSonicPropertiesFormPi", newValue);
    commit("setSonicPropertiesFormPiUnit", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesTiUnit({ commit }, value) {
    let newValue = logic.input.temperature.convert(
      state.form.properties.Ti.value,
      state.form.properties.Ti.unit,
      value
    );
    commit("setSonicPropertiesFormTi", newValue);
    commit("setSonicPropertiesFormTiUnit", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesPeUnit({ commit }, value) {
    let newValue = logic.input.pressure.convert(
      state.form.properties.Pe.value,
      state.form.properties.Pe.unit,
      value
    );
    commit("setSonicPropertiesFormPe", newValue);
    commit("setSonicPropertiesFormPeUnit", value);
    // dispatch("runSonicCalculator");
  },
  storeSonicPropertiesHiUnit({ commit }, value) {
    let newValue = logic.input.enthalpy.convert(
      state.form.properties.Hi.value,
      state.form.properties.Hi.unit,
      value
    );
    commit("setSonicPropertiesFormHi", newValue);
    commit("setSonicPropertiesFormHiUnit", value);
    // dispatch("runPropsCalculator");
  },
  setSonicPartFormType({ commit }, value) {
    commit("setSonicPartFormType", value);
    if (value === "valve") {
      commit("setSonicPartFormFlowUnit", "kv");
    } else if (value === "elbow") {
      commit("setSonicPartFormFlowUnit", "k");
    }
  },
  async setSonicPartFormInletSizing(
    { commit },
    { sizing = "DIN", size = null, norm = null }
  ) {
    return await new Promise((resolve, reject) => {
      commit("setSonicPartFormInletSizing", sizing);
      if (sizing === "ANSI") {
        commit("setSonicSizingListInlet", ANSI);
        commit("setSonicNormListInlet", ANSI[0].schedule);
        if (size === null) {
          size = "1/2";
        }
        commit("setSonicPartFormStandardInletSize", size);
        if (norm === null) {
          norm = "40";
        }
        commit("setSonicPartFormStandardInletNorm", norm);
        resolve({ message: "Inlet sizing set to ANSI" });
      } else if (sizing === "DIN") {
        commit("setSonicSizingListInlet", DIN);
        commit("setSonicNormListInlet", DIN[0].PN);
        if (size === null) {
          size = 10;
        }
        commit("setSonicPartFormStandardInletSize", size);
        if (norm === null) {
          norm = "40";
        }
        commit("setSonicPartFormStandardInletNorm", norm);
        resolve({ message: "Inlet sizing set to DIN" });
      } else if (sizing === "COSTUM") {
        resolve({ message: "Inlet sizing set to COSTUM" });
      } else {
        reject({ message: "Incorrect sizing specified" });
      }
    });
  },
  async setSonicPartFormOutletSizing(
    { commit },
    { sizing = "DIN", size = null, norm = null }
  ) {
    return await new Promise((resolve, reject) => {
      commit("setSonicPartFormOutletSizing", sizing);
      if (sizing === "ANSI") {
        commit("setSonicSizingListOutlet", ANSI);
        commit("setSonicNormListOutlet", ANSI[0].schedule);
        if (size === null) {
          size = "1/2";
        }
        commit("setSonicPartFormStandardOutletSize", size);
        if (norm === null) {
          norm = "40";
        }
        commit("setSonicPartFormStandardOutletNorm", norm);
        resolve({ message: "Outlet sizing set to ANSI" });
      } else if (sizing === "DIN") {
        commit("setSonicSizingListOutlet", DIN);
        commit("setSonicNormListOutlet", DIN[0].PN);
        if (size === null) {
          size = 10;
        }
        commit("setSonicPartFormStandardOutletSize", size);
        if (norm === null) {
          norm = "40";
        }
        commit("setSonicPartFormStandardOutletNorm", norm);
        resolve({ message: "Outlet sizing set to DIN" });
      } else if (sizing === "COSTUM") {
        resolve({ message: "Inlet sizing set to COSTUM" });
      } else {
        reject({ message: "Incorrect sizing specified" });
      }
    });
  },
  async setSonicPartFormStandardInletSize({ state, commit }, size) {
    return await new Promise((resolve, reject) => {
      commit("setSonicPartFormStandardInletSize", size);
      var NormList;
      if (state.form.part.inletSizing === "ANSI") {
        NormList = ANSI.filter((item) => item.NPS === size);
        commit("setSonicNormListInlet", NormList[0].schedule);
        if (NormList[0].schedule[state.form.part.inletNorm] === undefined) {
          commit("setSonicPartFormStandardInletNorm", "40");
        }
        resolve({ message: "Inlet sizing set" });
      } else if (state.form.part.inletSizing === "DIN") {
        NormList = DIN.filter((item) => item.DIN === size);
        commit("setSonicNormListInlet", NormList[0].PN);
        if (NormList[0].PN[state.form.part.inletNorm] === undefined) {
          commit("setSonicPartFormStandardInletNorm", "40");
        }
        resolve({ message: "Inlet sizing set" });
      } else {
        reject({ message: "Incorrect sizing specified" });
      }
    });
  },
  async setSonicPartFormStandardOutletSize({ state, commit }, size) {
    return await new Promise((resolve, reject) => {
      commit("setSonicPartFormStandardOutletSize", size);
      var NormList;
      if (state.form.part.outletSizing === "ANSI") {
        NormList = ANSI.filter((item) => item.NPS === size);
        commit("setSonicNormListOutlet", NormList[0].schedule);
        if (NormList[0].schedule[state.form.part.outletNorm] === undefined) {
          commit("setSonicPartFormStandardOutletNorm", "40");
        }
        resolve({ message: "Outlet sizing set" });
      } else if (state.form.part.outletSizing === "DIN") {
        NormList = DIN.filter((item) => item.DIN === size);
        commit("setSonicNormListOutlet", NormList[0].PN);
        if (NormList[0].PN[state.form.part.outletNorm] === undefined) {
          commit("setSonicPartFormStandardOutletNorm", "40");
        }
        resolve({ message: "Outlet sizing set" });
      } else {
        reject({ message: "Incorrect sizing specified" });
      }
    });
  },
  async storeSonicPartForm({ commit, dispatch }) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "storePart");
      var part = state.form.part;
      var data = "";
      if (part.type === "pipe") {
        data = {
          type: part.type,
          name: part.name,
          length: part.length,
          lengthUnit: part.lengthUnit || "m",
          flowValue: "",
          flowUnit: "",
          inletSizing: part.inletSizing,
          outletSizing: part.inletSizing,
          standardInletSize: part.standardInletSize,
          standardOutletSize: part.standardInletSize,
          standardInletNorm: part.standardInletNorm,
          standardOutletNorm: part.standardInletNorm,
          costumInletSize: part.costumInletSize,
          costumOutletSize: part.costumInletSize,
          costumInletSizeUnit: part.costumInletSizeUnit || "mm",
          costumOutletSizeUnit: part.costumInletSizeUnit || "mm",
        };
      } else if (part.type === "valve") {
        data = {
          type: part.type,
          name: part.name,
          length: "",
          lengthUnit: "",
          flowValue: part.flowValue,
          flowUnit: part.flowUnit || "kv",
          inletSizing: part.inletSizing,
          outletSizing: part.outletSizing,
          standardInletSize: part.standardInletSize,
          standardOutletSize: part.standardOutletSize,
          standardInletNorm: part.standardInletNorm,
          standardOutletNorm: part.standardOutletNorm,
          costumInletSize: part.costumInletSize,
          costumOutletSize: part.costumOutletSize,
          costumInletSizeUnit: part.costumInletSizeUnit || "mm",
          costumOutletSizeUnit: part.costumOutletSizeUnit || "mm",
        };
      } else {
        data = {
          type: part.type,
          name: part.name,
          length: "",
          lengthUnit: "",
          flowValue: part.flowValue,
          flowUnit: part.flowUnit || "k",
          inletSizing: part.inletSizing,
          outletSizing: part.outletSizing,
          standardInletSize: part.standardInletSize,
          standardOutletSize: part.standardInletSize,
          standardInletNorm: part.standardInletNorm,
          standardOutletNorm: part.standardInletNorm,
          costumInletSize: part.costumInletSize,
          costumOutletSize: part.costumInletSize,
          costumInletSizeUnit: part.costumInletSizeUnit || "mm",
          costumOutletSizeUnit: part.costumInletSizeUnit || "mm",
        };
      }

      if (part.edit === false) {
        var piping = state.form.piping;
        piping.push(data);
        commit("setSonicPipingForm", piping);
      } else {
        commit("editSonicPipingForm", data);
      }
      dispatch("clearSonicPartForm")
        .then((response) => {
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
  async editSonicPartForm({ commit, dispatch }, index) {
    const part = state.form.piping[index];
    commit("setSonicPartFormType", part.type);
    commit("setSonicPartFormName", part.name);
    commit("setSonicPartFormLength", part.length);
    commit("setSonicPartFormLengthUnit", part.lengthUnit || "m");
    commit("setSonicPartFormFlowValue", part.flowValue);
    if (part.type == "valve") {
      commit("setSonicPartFormFlowUnit", part.flowUnit || "kv");
    } else if (part.type == "elbow") {
      commit("setSonicPartFormFlowUnit", part.flowUnit || "k");
    } else {
      commit("setSonicPartFormFlowUnit", "");
    }
    if (part.inletSizing == "DIN" || part.inletSizing == "ANSI") {
      dispatch("setSonicPartFormInletSizing", {
        sizing: part.inletSizing,
        size: part.standardInletSize,
        norm: part.standardInletNorm,
      });
      dispatch("setSonicPartFormOutletSizing", {
        sizing: part.outletSizing,
        size: part.standardOutletSize,
        norm: part.standardOutletNorm,
      });
    } else {
      commit("setSonicPartFormCostumInletSize", part.costumInletSize);
      commit("setSonicPartFormCostumOutletSize", part.costumOutletSize);
      commit("setSonicPartFormCostumInletSizeUnit", part.costumInletSizeUnit);
      commit("setSonicPartFormCostumOutletSizeUnit", part.costumOutletSizeUnit);
    }
    commit("setSonicPartFormEdit", index);
  },
  async deleteSonicPart({ state, commit }, index) {
    var piping = [];
    state.form.piping.forEach(function (part, i) {
      if (i != index) {
        piping.push(part);
      }
    });
    commit("setSonicPipingForm", piping);
  },
  async deleteSonicPiping({ commit }) {
    commit("setSonicPipingForm", []);
  },
  async clearSonicPartForm({ commit, dispatch }) {
    return await new Promise((resolve) => {
      commit("setSonicPartFormType", null);
      commit("setSonicPartFormName", "");
      commit("setSonicPartFormLength", "");
      commit("setSonicPartFormLengthUnit", "m");
      commit("setSonicPartFormFlowValue", "");
      commit("setSonicPartFormFlowUnit", "kv");
      dispatch("setSonicPartFormInletSizing", "DIN");
      dispatch("setSonicPartFormOutletSizing", "DIN");
      commit("setSonicPartFormEdit", false);
      resolve({ message: "Part cleared" });
    });
  },

  async linkSonicProject({ commit }) {
    return await new Promise((resolve, reject) => {
      commit("openSonicRequest", "link");
      logic.request.sonic.project
        .link(state.project_id)
        .then((response) => {
          commit("setSonicProjects", response.data);
          commit("closeSonicRequest");
          resolve(response);
        })
        .catch((errors) => {
          commit("closeSonicRequest");
          reject(errors);
        });
    });
  },

  async shareProject({ commit }, value) {
    return await new Promise((resolve, reject) => {
      /*
      This would be something to work on at a later stays
      Sharing the project would give three different options
        1) Complete access
        2) Project duplication | Creates a new project identical to the current | Would allow an other user to use the project while keeping your data
        3) Project read | Would mean that you share the project without allowing the user to change any of the values
      */
      commit("openRequest", "share");
      var data = logic.request.validate();
      if (data === false) {
        commit("closeRequest");
        reject({ message: "No valid credentials" });
      }
      commit("setShareLink", value);
      commit("setSelectedShare", value);
      resolve("hex:80");
      // reject('hex:81')
      // commit('setShareLink', '/sonic?share=undefined')
      commit("closeRequest");
    });
  },
  async sharedProject({ commit }, value) {
    return await new Promise((resolve, reject) => {
      /*
            This would be something to work on at a later stays
            Sharing the project would give three different options
             1) Complete access
             2) Project duplication | Creates a new project identical to the current | Would allow an other user to use the project while keeping your data
             3) Project read | Would mean that you share the project without allowing the user to change any of the values
            */
      commit("openRequest", "share");
      var data = logic.request.validate();
      if (data === false) {
        commit("closeRequest");
        reject({ message: "No valid credentials" });
      }
      resolve(value);
      // reject('hex:81')
      // commit('setShareLink', '/sonic?share=undefined')
      commit("closeRequest");
    });
  },
};

const mutations = {
  setSonicProjectId: (state, value) => (state.project_id = value),
  setSonicProjects: (state, projects) => (state.projects = projects),
  setSonicProject: (state, project) => (state.project = project),
  appendSonicProject: (state, project) => state.projects.push(project),

  setSonicProjectName: (state, value) => (state.project.name = value),
  setSonicProjectDescription: (state, value) =>
    (state.project.description = value),

  setSonicStoredName: (state, name) => (state.stored.name = name),
  setSonicStoredDescription: (state, description) => (state.stored.description = description),
  setSonicStoredProperties: (state, properties) => (state.stored.properties = properties),
  setSonicStoredPiping: (state, piping) => (state.stored.piping = piping),

  setSonicResultProjectId: (state, projectId) =>
    (state.setResultProjectId = projectId),
  setSonicResultsProperties: (state, properties) =>
    (state.resultsProperties = properties),
  setSonicResults: (state, results) => (state.results = results),
  setSonicResultState: (state, value) => (state.resultState = value),

  setSonicProjectFormName: (state, value) => (state.form.project.name = value),
  setSonicProjectFormDescription: (state, value) =>
    (state.form.project.description = value),
  setSonicPropertiesFormPi: (state, value) =>
    (state.form.properties.Pi.value = value),
  setSonicPropertiesFormPiUnit: (state, value) =>
    (state.form.properties.Pi.unit = value),
  setSonicPropertiesFormTi: (state, value) =>
    (state.form.properties.Ti.value = value),
  setSonicPropertiesFormTiUnit: (state, value) =>
    (state.form.properties.Ti.unit = value),
  setSonicPropertiesFormPe: (state, value) =>
    (state.form.properties.Pe.value = value),
  setSonicPropertiesFormPeUnit: (state, value) =>
    (state.form.properties.Pe.unit = value),

  setSonicPartFormEdit: (state, value) => (state.form.part.edit = value),
  setSonicPartFormType: (state, value) => (state.form.part.type = value),
  setSonicPartFormName: (state, value) => (state.form.part.name = value),
  setSonicPartFormLength: (state, value) => (state.form.part.length = value),
  setSonicPartFormLengthUnit: (state, value) =>
    (state.form.part.lengthUnit = value),
  setSonicPartFormFlowValue: (state, value) =>
    (state.form.part.flowValue = value),
  setSonicPartFormFlowUnit: (state, value) =>
    (state.form.part.flowUnit = value),

  setSonicPartFormInletSizing: (state, value) =>
    (state.form.part.inletSizing = value),
  setSonicPartFormOutletSizing: (state, value) =>
    (state.form.part.outletSizing = value),
  setSonicSizingListInlet: (state, value) =>
    (state.form.sizingListInlet = value),
  setSonicSizingListOutlet: (state, value) =>
    (state.form.sizingListOutlet = value),
  setSonicNormListInlet: (state, value) => (state.form.normListInlet = value),
  setSonicNormListOutlet: (state, value) => (state.form.normListOutlet = value),
  setSonicPartFormStandardInletSize: (state, value) =>
    (state.form.part.standardInletSize = value),
  setSonicPartFormStandardOutletSize: (state, value) =>
    (state.form.part.standardOutletSize = value),
  setSonicPartFormStandardInletNorm: (state, value) =>
    (state.form.part.standardInletNorm = value),
  setSonicPartFormStandardOutletNorm: (state, value) =>
    (state.form.part.standardOutletNorm = value),
  setSonicPartFormInletCostumSize: (state, value) =>
    (state.form.part.costumInletSize = value),
  setSonicPartFormInletCostumSizeUnit: (state, value) =>
    (state.form.part.costumInletSizeUnit = value),
  setSonicPartFormOutletCostumSize: (state, value) =>
    (state.form.part.costumOutletSize = value),
  setSonicPartFormOutletCostumSizeUnit: (state, value) =>
    (state.form.part.costumOutletSizeUnit = value),
  setSonicPipingForm(state, piping) {
    Vue.set(state.form, "piping", piping);
    state.form.piping = piping;
  },
  editSonicPipingForm(state, part) {
    const index = state.form.part.edit;
    Vue.set(state.form.piping, index, part);
    state.form.piping[index] = part;
  },

  setSonicPropertiesComplete: (state, value) =>
    (state.project.complete.properties = value),
  setSonicPipingComplete: (state, value) =>
    (state.project.complete.piping = value),

  openSonicRequest: (state, type) =>
    (state.request = { running: true, type: type }),
  closeSonicRequest: (state) =>
    (state.request = { running: false, type: "undefined" }),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
