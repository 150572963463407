const Name = {
  min: 2,
  max: 63,
  validate(name) {
    name = String(name)
    return name.length >= Name.min && name.length <= Name.max;
  },
};

export default Name;
